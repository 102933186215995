import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase/app';

import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { UsersService } from '../users/users.service';
import { User } from 'models/users.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authUser$: Observable<firebase.User>;
  user$: Observable<User>;
  userId: string;

  remember: boolean;

  constructor(
    private firebaseAuth: AngularFireAuth,
    private usersService: UsersService
  ) {
    this.authUser$ = firebaseAuth.user;
    this.authUser$.subscribe({
      next: (authUser) => {
        if (authUser) {
          this.userId = authUser.uid;

          this.user$ = this.usersService.getUser({ userId: this.userId })
            .pipe(takeWhile(val => !!authUser));

          // listen for claims updated and refresh token
          let updatingToken = false;
          this.user$.subscribe(user => {
            this.firebaseAuth.auth.currentUser.getIdTokenResult().then(idTokenResult => {
              if (user.claimsUpdatedAt && !updatingToken) {
                const tokenIssuedDate = new Date(idTokenResult.issuedAtTime);

                if (+tokenIssuedDate < +user.claimsUpdatedAt.toDate()) {
                  updatingToken = true;
                  this.firebaseAuth.auth.currentUser.getIdToken(true)
                    .then(() => {
                      updatingToken = false;
                    });
                }
              }
            });
          });

        } else {
          this.userId = undefined;
        }
      }
    });
  }

  getAuthUser(): Observable<firebase.User> {
    return this.authUser$;
  }

  getUser(): Observable<User> {
    return this.user$;
  }

  getUserId(): string {
    return this.userId;
  }

  signUp({name, email, password}: {name: string, email: string, password: string}): Promise<void> {
    return this.firebaseAuth.auth
      .createUserWithEmailAndPassword(email, password)
      .then((val) => {
        this.firebaseAuth.auth.currentUser.sendEmailVerification();

        return this.usersService.createUser({
          id: val.user.uid,
          name: name,
          email: val.user.email,
          photoURL: null
        });
      });
  }

  signIn(email: string, password: string, remember: boolean): Promise<firebase.auth.UserCredential> {
    let type = firebase.auth.Auth.Persistence.SESSION;
    if (remember) {
      type = firebase.auth.Auth.Persistence.LOCAL;
    }
    this.remember = remember;

    return this.firebaseAuth.auth.setPersistence(type)
      .then(() => {
        return this.firebaseAuth.auth
          .signInWithEmailAndPassword(email, password);
      });
  }

  signOut(): Promise<void> {
    return this.firebaseAuth.auth
      .signOut();
  }

  sendForgotPassword(email: string): Promise<void> {
    return this.firebaseAuth.auth
      .sendPasswordResetEmail(email);
  }

  verifyPasswordResetCode(code: string): Promise<string> {
    return this.firebaseAuth.auth
      .verifyPasswordResetCode(code);
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return this.firebaseAuth.auth
      .confirmPasswordReset(code, newPassword);
  }

  verifyRecoverEmailCode(code: string): Promise<firebase.auth.ActionCodeInfo> {
    return this.firebaseAuth.auth
      .checkActionCode(code);
  }

  updateEmail(email: string, password: string): Promise<void> {
    return this.signIn(this.firebaseAuth.auth.currentUser.email, password, this.remember)
      .then(() => {
        return this.firebaseAuth.auth.currentUser.updateEmail(email)
          .then(() => {
            return this.firebaseAuth.auth.currentUser.sendEmailVerification();
          });
      });
  }

  sendEmailVerification(): Promise<void> {
    return this.firebaseAuth.auth.currentUser.sendEmailVerification();
  }

  applyActionCode(code: string): Promise<void> {
    return this.firebaseAuth.auth
      .applyActionCode(code);
  }

  updatePassword(currentPassword: string, newPassword: string): Promise<any> {
    return this.signIn(this.firebaseAuth.auth.currentUser.email, currentPassword, this.remember)
      .then(auth => {
        return this.firebaseAuth.auth.currentUser.updatePassword(newPassword);
      });
  }
}
