import { Component, Input, OnInit } from '@angular/core';
import { ProjectsService } from '../projects.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Company } from 'models/companies.model';
import { Project } from 'models/projects.model';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateProjectComponent implements OnInit {
  submitted: boolean;
  projectForm: FormGroup;
  name: FormControl;
  description: FormControl;

  @Input() company: Company;

  @Input() project: Project = {
    companyId: '',
    listId: '',
    name: '',
    description: '',
    users: {}
  };

  constructor(
    private activeModal: NgbActiveModal,
    private projectsService: ProjectsService,
    private toastr: ToastrService
  ) {
    this.submitted = false;
  }

  createFormControls() {
    this.name = new FormControl(this.project.name || '', [
      Validators.required,
    ]);
    this.description = new FormControl(this.project.description || '');
  }

  createForm() {
    this.projectForm = new FormGroup({
      name: this.name,
      description: this.description,
    });
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  save() {
    if (this.projectForm.status === 'INVALID') {
      this.submitted = true;

      return;
    }

    // Create
    if (!this.project.id) {
      this.projectsService.create({
        ...this.project,
        name: this.name.value,
        description: this.description.value,
        companyId: this.company.id,
        listId: undefined
      })
        .then(() => {
          this.closeModal();
        })
        .catch((err) => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error(err);
        });

    // Update
    } else {

      // TODO: (DAS-4) should ONLY allow updating specific fields
      this.projectsService.update({
        ...this.project,
        name: this.name.value,
        description: this.description.value,
      })
        .then(() => {
          this.closeModal();
        })
        .catch((err) => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error(err);
        });
    }
  }

  closeModal() {
    this.activeModal.close();
  }

}
