import { Injectable } from '@angular/core';
import { FirestoreService, BatchElement } from '../firestore.service';
import { Observable } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import { UserInvite } from 'models/users.model';
import { Company } from 'models/companies.model';

@Injectable({
  providedIn: 'root'
})
export class UserInvitesService {

  constructor(
    private afFunctions: AngularFireFunctions,
    private db: FirestoreService
  ) { }

  getUserInvites({ email }: { email: string }): Observable<UserInvite[]> {
    return this.db.collectionWithIds$<UserInvite>(`userInvites`, ref => {
      return ref.where(`email`, '==', email);
    });
  }

  getCompanyUserInvites({ companyId }: { companyId: string }): Observable<UserInvite[]> {
    return this.db.collectionWithIds$(`companies/${companyId}/userInvites`);
  }

  getProjectUserInvites({ projectId }: { projectId: string }): Observable<UserInvite[]> {
    return this.db.collectionWithIds$(`projects/${projectId}/userInvites`);
  }

  deleteCompanyUserInvite(companyId: string, userInvite: UserInvite): Promise<void> {
    const batch: BatchElement[] = [];

    const companyInviteRef = this.db.doc(`companies/${companyId}/userInvites/${userInvite.id}`).ref;
    batch.push({
      ref: companyInviteRef,
      data: {},
      type: 'delete'
    });

    const userInviteRef = this.db.doc(`userInvites/${userInvite.id}`).ref;
    batch.push({
      ref: userInviteRef,
      data: {},
      type: 'delete'
    });

    return this.db.batch(batch);
  }

  deleteProjectUserInvite(projectId: string, userInvite: UserInvite): Promise<void> {
    const batch: BatchElement[] = [];

    const projectInviteRef = this.db.doc(`projects/${projectId}/userInvites/${userInvite.id}`).ref;
    batch.push({
      ref: projectInviteRef,
      data: {},
      type: 'delete'
    });

    const userInviteRef = this.db.doc(`userInvites/${userInvite.id}`).ref;
    batch.push({
      ref: userInviteRef,
      data: {},
      type: 'delete'
    });

    return this.db.batch(batch);
  }

  // TODO: (DAS-11) Check that they're not already invited
  // TODO: (DAS-11) check that they're not already part of the company
  inviteUserToCompany({ companyId, companyName, email }: { companyId: string, companyName: string, email: string }): Promise<void> {
    const companyInviteRef = this.db.collection<Company>(`companies/${companyId}/userInvites`).ref.doc();
    const userInviteRef = this.db.collection(`userInvites`).ref.doc(companyInviteRef.id);

    return this.db.batch([
      {
        ref: companyInviteRef,
        data: {
          email
        },
        type: 'set'
      },
      {
        ref: userInviteRef,
        data: {
          email,
          type: 'company',
          docId: companyId,
          docName: companyName
        },
        type: 'set'
      }
    ]);
  }

  // TODO: (DAS-11) Check that they're not already invited
  // TODO: (DAS-11) check that they're not already part of the project
  inviteUserToProject({ projectId, projectName, email }: { projectId: string, projectName: string, email: string }): Promise<void> {
    const projectInviteRef = this.db.collection<Company>(`projects/${projectId}/userInvites`).ref.doc();
    const userInviteRef = this.db.collection(`userInvites`).ref.doc(projectInviteRef.id);

    return this.db.batch([
      {
        ref: projectInviteRef,
        data: {
          email
        },
        type: 'set'
      },
      {
        ref: userInviteRef,
        data: {
          email,
          type: 'project',
          docId: projectId,
          docName: projectName
        },
        type: 'set'
      }
    ]);
  }

  joinFromInvite(userInvite: UserInvite): Observable<any> {
    if (userInvite.type === 'company') {
      return this.afFunctions.httpsCallable('userInviteJoinCompany')({ id: userInvite.id });

    } else if (userInvite.type === 'project') {
      return this.afFunctions.httpsCallable('userInviteJoinProject')({ id: userInvite.id });
    }
  }
}
