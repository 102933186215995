import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ProjectsService } from '../../projects/projects.service';
import { CompaniesService } from '../../companies/companies.service';
import { ListsService } from '../../lists/lists.service';
import { TasksService } from '../../tasks/tasks.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateProjectComponent } from '../../projects/create/create.component';
import { CreateCompanyComponent } from '../../companies/create/create.component';
import { CreateListComponent } from '../../lists/create/create.component';
import { CreateTaskComponent } from '../../tasks/create/create.component';
import { PopupService } from '../../popups/popup.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ItemsService } from '../../items/items.service';
import { CreateItemComponent } from '../../items/create/create.component';
import { Company } from 'models/companies.model';
import { Project } from 'models/projects.model';
import { List } from 'models/lists.model';
import { Task } from 'models/tasks.model';
import { Item } from 'models/items.model';
import { MoveResourceModalComponent } from '../move-resource-modal/move-resource-modal.component';

@Component({
  selector: 'app-manage-resource-dropdown',
  templateUrl: './manage-resource-dropdown.component.html',
  styleUrls: ['./manage-resource-dropdown.component.scss']
})
export class ManageResourceDropdownComponent implements OnInit, OnChanges {
  @Input() company: Company;
  @Input() project: Project;
  @Input() list: List;
  @Input() task: Task;
  @Input() item: Item;

  @Input() type: 'more' | 'settings' = 'more';

  @Input() showView = true;
  @Input() showEdit = true;
  @Input() showMove = true;
  @Input() showDelete = true;

  resource: {
    typeName: string;
    route: string;
    id: string;
    object: Company | Project | List | Task | Item;
    modalComponent: any;
  };

  constructor(
    private companiesService: CompaniesService,
    private itemsService: ItemsService,
    private listsService: ListsService,
    private modalService: NgbModal,
    private popupService: PopupService,
    private projectsService: ProjectsService,
    private router: Router,
    private tasksService: TasksService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    if (this.company) {
      this.resource = {
        typeName: 'company',
        route: '/companies/',
        id: this.company.id,
        object: this.company,
        modalComponent: CreateCompanyComponent
      };

    } else if (this.project) {
      this.resource = {
        typeName: 'project',
        route: '/projects/',
        id: this.project.id,
        object: this.project,
        modalComponent: CreateProjectComponent
      };

    } else if (this.list) {
      this.resource = {
        typeName: 'list',
        route: '/lists/',
        id: this.list.id,
        object: this.list,
        modalComponent: CreateListComponent
      };

    } else if (this.task) {
      this.resource = {
        typeName: 'task',
        route: '/tasks/',
        id: this.task.id,
        object: this.task,
        modalComponent: CreateTaskComponent
      };

    } else if (this.item) {
      this.resource = {
        typeName: 'item',
        route: '/items/',
        id: this.item.id,
        object: this.item,
        modalComponent: CreateItemComponent
      };

    }

    if (this.type === 'settings') {
      this.showView = false;
    }

    if (['item', 'list', 'task'].indexOf(this.resource.typeName) === -1) {
      this.showMove = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['company'] || changes['project'] || changes['list'] || changes['task'] || changes['item']) {
      this.ngOnInit();
    }
  }

  edit() {
    const modalRef = this.modalService.open(this.resource.modalComponent);
    modalRef.componentInstance[this.resource.typeName] = this.resource.object;
  }

  move() {
    const modalRef = this.modalService.open(MoveResourceModalComponent);
    if (this.resource.typeName === 'item') {
      modalRef.componentInstance.items = [this.resource.object];

    } else {
      modalRef.componentInstance.elements = [this.resource.object];
    }
  }

  delete() {
    let extraMessage = ``;
    if (this.resource.typeName === 'item' && this.resource.object['type'] === 'file') {
      extraMessage += `<br />This will delete the associated file as well.`;
    }

    this.popupService.confirm({
      message: `Are you sure you want to delete <strong>${this.resource.object.name}</strong>? ${extraMessage}`,
      title: `Delete ${this.resource.typeName}`
    })
      .then(() => {
        const errorHandler = (error) => {
          this.toastr.error('Oops, something went wrong.');
          console.error(error);
        };

        switch (this.resource.typeName) {
          case 'company':
            this.companiesService.delete(this.company)
              .then(() => {
                this.router.navigate(['/home/']);
              })
              .catch(errorHandler);
            break;

          case 'project':
            this.projectsService.delete(this.project)
              .then(() => {
                this.router.navigate(['/companies/', this.project.companyId]);
              })
              .catch(errorHandler);
            break;

          case 'list':
            this.listsService.delete(this.list)
              .then(() => {
                this.router.navigate(['/lists/', this.list.parentListId]);
              })
              .catch(errorHandler);
            break;

          case 'task':
            this.tasksService.delete(this.task)
              .then(() => {
                this.router.navigate(['/lists/', this.task.parentListId]);
              })
              .catch(errorHandler);
            break;

          case 'item':
            this.itemsService.delete(this.item)
              .then(() => {
                // TODO: only navigate if not already on this route
                this.router.navigate(['/tasks/', this.item.id]);
              })
              .catch(errorHandler);
            break;
        }
      })
      .catch(() => { });
  }

}
