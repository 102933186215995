import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreService } from '../firestore.service';
import { Task } from 'models/tasks.model';

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(
    private db: FirestoreService
  ) {

  }

  getTasks({ listId, projectId }: { listId?: string, projectId: string }): Observable<Task[]> {
    return this.db.collectionWithIds$<Task>('tasks', ref => {
      if (listId) {
        return ref.where(`parentListId`, '==', listId).where('projectId', '==', projectId);
      }

      return ref.where('projectId', '==', projectId);
    });
  }

  getTask({ taskId }: { taskId: string }): Observable<Task> {
    return this.db.doc$<Task>(`tasks/${taskId}`)
      .pipe(
        map(task => {
          return {
            id: taskId,
            ...task
          } as Task;
        })
      );
  }

  create(task: Task): Promise<firebase.firestore.DocumentReference> {
    return this.db.add<Task>('tasks', task);
  }

  update(task: Task): Promise<void> {
    // TODO: (DAS-4) high chance of other data being manipulated here
    return this.db.update<Task>(`tasks/${task.id}`, task);
  }

  delete(task: Task): Promise<void> {
    return this.db.delete(`tasks/${task.id}`);
  }
}
