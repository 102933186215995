import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { UnsubscribeOnDestroy } from 'src/classes/unsubscribe-on-destroy';
import { List, ListElements } from 'models/lists.model';
import { Task } from 'models/tasks.model';
import { Item } from 'models/items.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateListComponent } from '../lists/create/create.component';
import { CreateTaskComponent } from '../tasks/create/create.component';
import { CreateItemComponent } from '../items/create/create.component';
import { ToolbarService } from './toolbar.service';
import { takeUntil } from 'rxjs/operators';
import { MoveResourceModalComponent } from '../utilities/move-resource-modal/move-resource-modal.component';
import { PopupService } from '../popups/popup.service';
import { ProjectsService } from '../projects/projects.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
  @Input() list?: List;
  @Input() task?: Task;

  // really just Set<List | Task> | Set<Item> ... not working for some reason
  selectedElements: Set<List | Task | Item> = new Set();

  selectedElementsAreItems = false;

  constructor(
    private modalService: NgbModal,
    private popupService: PopupService,
    private projectsService: ProjectsService,
    private toastr: ToastrService,
    private toolbarService: ToolbarService
  ) {
    super();
  }

  ngOnInit() {
    this.toolbarService.getSelectedElements()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(elements => {
        this.selectedElements = elements;

        this.selectedElementsAreItems = false;
        for (const el of elements) {
          // won't work if we ever need to select tasks, lists and items...
          if (el.type !== 'list' && el.type !== 'task') {
            this.selectedElementsAreItems = true;
            break;
          }
        }
      });
  }

  ngOnChanges() {
  }

  addList() {
    const modalRef = this.modalService.open(CreateListComponent);
    modalRef.componentInstance.projectId = this.list.projectId;
    modalRef.componentInstance.parentListId = this.list.id;
  }

  addTask() {
    const modalRef = this.modalService.open(CreateTaskComponent);
    modalRef.componentInstance.projectId = this.list.projectId;
    modalRef.componentInstance.parentListId = this.list.id;
  }

  addItem() {
    const modalRef = this.modalService.open(CreateItemComponent);
    modalRef.componentInstance.task = this.task;
  }

  moveSelectedElements() {
    const modalRef = this.modalService.open(MoveResourceModalComponent);

    if (this.selectedElementsAreItems) {
      modalRef.componentInstance.items = Array.from(this.selectedElements);

    } else {
      modalRef.componentInstance.elements = Array.from(this.selectedElements);
    }
  }

  deleteSelectedElements() {
    let typeName = (this.selectedElementsAreItems) ? 'item' : 'element';
    // TODO: pluralize
    if (this.selectedElements.size > 1) {
      typeName += 's';
    }

    this.popupService.confirm({
      title: `Delete ${typeName}`,
      message: `Are you sure you want to <strong>delete ${this.selectedElements.size} ${typeName}</strong>?`
    })
      .then(() => {
        let promise;
        if (this.selectedElementsAreItems) {
          promise = this.projectsService.deleteItems({ items: Array.from(this.selectedElements) as Item[] });

        } else {
          promise = this.projectsService.deleteElements({ elements: Array.from(this.selectedElements) as ListElements });
        }

        promise.then(() => {
          this.toastr.success(`${this.selectedElements.size} ${typeName} ${(this.selectedElements.size) === 1 ? 'was' : 'were'} deleted.`);
        })
        .catch(() => {
          this.toastr.error(`Oops, there was an issue deleting. Please try again.`);
        });
      })
      .catch();
  }

}
