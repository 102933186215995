import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import plyr from 'plyr';

@Component({
  selector: 'app-plyr',
  templateUrl: './plyr.component.html',
  styleUrls: ['./plyr.component.scss']
})
export class PlyrComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() src: string;
  @Input() type: 'audio' | 'video';

  player;

  @ViewChild('plyrElement') plyrElement: ElementRef;

  constructor() {
    super();
  }

  ngOnInit() {
    setTimeout(() => {
      this.player = new plyr(this.plyrElement.nativeElement, {
        controls: [
          'play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'pip', 'airplay', 'fullscreen'
        ]
      });
    }, 0);
  }

}
