import { Component, Input, OnInit } from '@angular/core';
import { ProjectsService } from '../projects.service';
import { ListsService } from '../../lists/lists.service';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { takeUntil } from 'rxjs/operators';
import { Project } from 'models/projects.model';
import { List } from 'models/lists.model';
import { User } from 'models/users.model';

@Component({
  selector: 'app-project-list-item',
  templateUrl: './project-list-item.component.html',
  styleUrls: ['./project-list-item.component.scss']
})
export class ProjectListItemComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() project: Project;
  list: List;
  projectUsers: User[];

  constructor(
    private listsService: ListsService,
    private projectsService: ProjectsService
  ) {
    super();
  }

  ngOnInit() {
    this.listsService.getList({listId: this.project.listId})
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(list => {
        this.list = list;
      });

    this.projectsService.getUsers({ projectId: this.project.id })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(users => {
        this.projectUsers = users;
      });
  }
}
