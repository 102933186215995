import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Task } from 'models/tasks.model';

@Component({
  selector: 'app-task-list-item',
  templateUrl: './task-list-item.component.html',
  styleUrls: ['./task-list-item.component.scss']
})
export class TaskListItemComponent {
  @Input() task: Task;
  @Input() isChecked = false;

  @Output() changeTask = new EventEmitter<Task>();

  constructor() { }

  change() {
    this.changeTask.emit(this.task);
  }

}
