import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreService } from '../firestore.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Item } from 'models/items.model';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {

  constructor(
    private afFunctions: AngularFireFunctions,
    private db: FirestoreService
  ) { }

  getItems({ taskId, projectId }: { taskId?: string, projectId: string }): Observable<Item[]> {
    return this.db.collectionWithIds$<Item>('items', ref => {
      if (taskId) {
        // project ID required for project firestore rules permissions
        return ref.where(`taskObj.id`, '==', taskId).where(`projectId`, '==', projectId);
      } else {
        return ref.where(`projectId`, '==', projectId);
      }
    });
  }

  getItem({ itemId }: { itemId: string }): Observable<Item> {
    return this.db.doc$<Item>(`items/${itemId}`)
      .pipe(
        map(item => {
          return {
            id: itemId,
            ...item
          } as Item;
        })
      );
  }

  getItemUrl(item: Item, preview?: boolean): Observable<any> {
    if (item.type !== 'file') {
      return of(item.urlMeta.url);
    }

    const data = {
      item: item,
      bucket: item.fileMeta.bucket,
      bucketPath: item.fileMeta.bucketPath,
      preview: preview
    };

    if (preview && item.fileMeta.bucketPathPreview) {
      data.bucketPath = item.fileMeta.bucketPathPreview;
    }

    return this.afFunctions.httpsCallable('storageSignedUrl')(data);
  }

  create(item: Item): Promise<firebase.firestore.DocumentReference> {
    return this.db.add<Item>('items', item);
  }

  update(item: Item): Promise<void> {
    // TODO: (DAS-4) high chance of other data being manipulated here
    return this.db.update<Item>(`items/${item.id}`, item);
  }

  delete(item: Item): Promise<void> {
    return this.db.delete(`items/${item.id}`);
  }
}
