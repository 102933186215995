import { Component, Input, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ItemsService } from '../items.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorageReference, AngularFireUploadTask, AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { URLValidator } from '../../validators/validators';
import { environment } from '../../../environments/environment';
import { Task } from 'models/tasks.model';
import { Item } from 'models/items.model';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateItemComponent extends UnsubscribeOnDestroy implements OnInit, AfterViewInit {
  submitted: boolean;
  fileMissing: boolean;
  fileChangeListenerSetUp: boolean;
  itemForm: FormGroup;
  description: FormControl;
  name: FormControl;
  url: FormControl;
  type: FormControl;

  @Input() task: Task;

  @Input() item: Item = {
    projectId: '',
    taskId: '',
    name: '',
    description: '',
    type: 'file'
  };

  @ViewChild('fileInput') fileInput: ElementRef;
  file: {
    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
    uploadProgress: Observable<number>;
    downloadURL: Observable<any>;
  } = {
    ref: undefined,
    task: undefined,
    uploadProgress: undefined,
    downloadURL: undefined
  };
  chosenFileName = 'No file chosen';

  constructor(
    private activeModal: NgbActiveModal,
    private afStorage: AngularFireStorage,
    private itemsService: ItemsService,
    private toastr: ToastrService
  ) {
    super();
    this.submitted = false;
    this.fileMissing = false;
    this.fileChangeListenerSetUp = false;
  }

  createFormControls() {
    this.name = new FormControl(this.item.name || '', [
      Validators.required
    ]);
    this.description = new FormControl(this.item.description || '');
    this.type = new FormControl(this.item.type || '');
    this.url = new FormControl('', this.type.value === 'url' ? Validators.compose([Validators.required, URLValidator]) : undefined);
  }

  createForm() {
    this.itemForm = new FormGroup({
      name: this.name,
      description: this.description,
      url: this.url,
      type: this.type,
    });
  }

  createFormOptionalValidators() {
    this.type.valueChanges.subscribe(
      (type: string) => {
        if (type === 'url') {
          this.url.setValidators(Validators.compose([Validators.required, URLValidator]));
        } else if (type === 'file') {
          this.url.setValidators([]);

          // TODO doesn't exactly belong here and needs to be moved, but for now it's ok
          if (!this.fileChangeListenerSetUp) {
            this.setUpFileChangeListener();
          }
        }
      }
    );
  }

  setUpFileChangeListener() {
    setTimeout(() => { // we need this timeout because right at this very moment the file input won't be present, because of the ngIf on it
      if (this.fileInput && this.fileInput.nativeElement) {
        this.fileChangeListenerSetUp = true;

        this.fileInput.nativeElement.onchange = (event) => {
          if (!event.target.value || !event.target.files || !event.target.files[0]) {
            return;
          }

          this.fileMissing = false;

          const name = event.target.files[0].name;
          const maxLen = 30;
          if (name.length <= maxLen) {
            this.chosenFileName = name;
            return;
          }

          const separator = '...';
          const sepLen = separator.length;
          const charsToShow = maxLen - sepLen;
          const frontChars = Math.ceil(charsToShow / 2);
          const backChars = Math.floor(charsToShow / 2);

          this.chosenFileName = name.substr(0, frontChars) + separator +
            name.substr(name.length - backChars);
        };
      }
    }, 0); // so just put this registering off until the internal variable change happens
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.createFormOptionalValidators();

    // editing
    if (this.item.id) {
      if (this.item.type === 'url') {
        this.url.setValue(this.item.urlMeta.url);
      }
    }
  }

  ngAfterViewInit() {
    this.setUpFileChangeListener();
  }

  gatherItemValues() {
    return {
      type: this.type.value,
      name: this.name.value,
      description: this.description.value,
    };
  }

  save() {
    if (this.itemForm.status === 'INVALID') {
      this.submitted = true;

      if (this.type.value === 'file') {
        const fileBrowser = this.fileInput.nativeElement;

        if ((fileBrowser.files && !fileBrowser.files[0]) && !this.item.fileMeta) {
          this.fileMissing = true;
        }
      } else {
        this.fileMissing = false;
      }

      return;
    }

    const continueSave = () => {
      // Create
      if (!this.item.id) {
        this.itemsService.create({
          ...this.item,
          ...this.gatherItemValues(),
          projectId: this.task.projectId,
          taskId: this.task.id,
          taskObj: {
            id: this.task.id,
            name: this.task.name
          }
        })
          .then(() => {
            this.closeModal();
          })
          .catch((err) => {
            this.toastr.error('Oops, something went wrong. Please try again.');
            console.error(err);
          });

        // Update
      } else {
        this.itemsService.update({ ...this.item, ...this.gatherItemValues() })
          .then(() => {
            this.closeModal();
          })
          .catch((err) => {
            this.toastr.error('Oops, something went wrong. Please try again.');
            console.error(err);
          });
      }
    };

    if (this.type.value === 'url') {
      // TODO: (DAS-34) possibly pull opengraph info (cloud function)
      // https://opengraph.io/api/1.1/site/https%3A%2F%2Ftwitter.com%2Fachendrick?app_id=5b775fa6827bd6820c7fc84d
      this.item.urlMeta = {
        url: this.url.value,
      };

      continueSave();

    } else if (this.type.value === 'file') {
      const fileBrowser = this.fileInput.nativeElement;

      if (fileBrowser.files && fileBrowser.files[0]) {
        const file = fileBrowser.files[0];

        if (file.size > 2 * 1024 * 1024) {
          this.toastr.info(`This file is too large, please choose a new one that's less than 2MB.`);
          return;
        }

        const projectId = (this.task) ? this.task.projectId : this.item.projectId;

        // TODO: chance of collision
        const fileName = `${Math.random().toString(36).substring(2)}_${encodeURI(file.name)}`;
        const bucketPath = `projects/${projectId}/items/${fileName}`;

        this.file.ref = this.afStorage.ref(bucketPath);
        this.file.task = this.file.ref.put(file);
        this.file.uploadProgress = this.file.task.percentageChanges();
        this.file.task
          .snapshotChanges()
          .pipe(
            finalize(() => {
              // cloud function overrides some of this
              this.item.fileMeta = {
                extension: file.name.slice((Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1),
                mimetype: file.type,
                name: fileName,
                bucket: environment.firebase.storageBucket,
                bucketPath: bucketPath
              };

              continueSave();
            })
          )
          .subscribe(
            () => {
            },
            (err) => {
              this.toastr.error('Oops, something went wrong. Please try again.');
              console.error(err);
            }
          );

      } else if (this.item.fileMeta) {
        continueSave();

      } else {
        this.fileMissing = true;
        return;
      }
    }
  }

  closeModal() {
    this.activeModal.close();
  }

}
