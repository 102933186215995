import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent implements OnInit {
  @Input() message: string;
  @Input() title: string;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    if (!this.title) {
      this.title = 'Confirm';
    }
  }

  closePopup() {
    this.activeModal.dismiss();
  }

  confirmPopup() {
    this.activeModal.close();
  }

}
