import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ListElements, List } from 'models/lists.model';
import { Task } from 'models/tasks.model';
import { ListsService } from '../lists.service';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Project } from 'models/projects.model';
import { SimpleUser } from 'models/users.model';
import { TasksService } from '../../tasks/tasks.service';
import { ToolbarService } from 'src/app/toolbar/toolbar.service';

@Component({
  selector: 'app-list-elements',
  templateUrl: './list-elements.component.html',
  styleUrls: ['./list-elements.component.scss']
})
export class ListElementsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
  private sortProperty = 'name';

  @Input() list: List;
  @Input() project: Project;
  @Input() type: 'project' | 'project:tasks' | 'list' | 'list:tasks';

  listElements: ListElements = [];
  selectedListElements: Set<List | Task> = new Set();
  isLoadingListElements = true;
  filteredListElements: ListElements = [];
  filteredUser: SimpleUser;

  constructor(
    private listsService: ListsService,
    private tasksService: TasksService,
    private toastr: ToastrService,
    private toolbarService: ToolbarService
  ) {
    super();
  }

  ngOnInit() {
    this.resetSelectedListElements();

    if (this.type === 'project' || this.type === 'list') {
      this.listsService.getListElements({ listId: this.list.id, projectId: this.list.projectId })
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(listElements => {
          this.setListElements(listElements);
          this.isLoadingListElements = false;
        }, (err) => {
          this.toastr.error('Oops, something went wrong.');
          console.error(err);
        });

    } else if (this.type === 'project:tasks' || this.type === 'list:tasks') {
      this.tasksService.getTasks({ projectId: this.project.id })
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(taskElements => {
          this.setListElements(taskElements);
          this.isLoadingListElements = false;
        }, (err) => {
          this.toastr.error('Oops, something went wrong.');
          console.error(err);
        });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['list'] || changes['project'] || changes['type']) {
      this.ngOnInit();
    }
  }

  private setListElements(listElements: ListElements): void {
    this.listElements = listElements.sort((a, b) => {
      return (a[this.sortProperty].toLowerCase() < b[this.sortProperty].toLowerCase()) ? -1 : 1;
    });

    this.filteredListElements = [...this.listElements];
  }

  resetSelectedListElements() {
    this.selectedListElements = new Set();
    this.toolbarService.setSelectedElements(this.selectedListElements);
  }

  changeSelectedListElements(element: List | Task) {
    if (this.selectedListElements.has(element)) {
      this.selectedListElements.delete(element);

    } else {
      this.selectedListElements.add(element);
    }

    this.toolbarService.setSelectedElements(this.selectedListElements);
  }

  selectAll(el: any) {
    if (el && el.target && el.target.checked) {
      this.selectedListElements = new Set(this.filteredListElements);
      this.toolbarService.setSelectedElements(this.selectedListElements);

    } else {
      this.resetSelectedListElements();
    }
  }

  filterByUser(user: SimpleUser | undefined) {
    this.filteredUser = user;

    this.filteredListElements = [...this.listElements].filter(element => {
      if (!user) {
        return true;
      }

      if (element.type === 'list') {
        return false;
      }

      // unassigned task
      if (!element.assigneeObj) {
        return false;
      }

      return element.assigneeObj.id === user.id;
    });
  }

}
