import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ListsService } from './lists.service';
import { List } from 'models/lists.model';

@Injectable({
  providedIn: 'root'
})
export class ListsResolverService implements Resolve<List> {

  constructor(
    private listsService: ListsService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<List> {
    let listId = route.paramMap.get('listId');
    if (!listId) {
      listId = route.queryParamMap.get('listId');
    }
    const isRoadmap = route.url.length === 3 && route.url[2].path === 'roadmap';

    return this.listsService.getList({listId}).pipe(
      take(1),
      map(list => {
        if (list) {
          if (!list.parentListId && !isRoadmap) {
            this.router.navigate(['/projects/', list.projectId]);
            return null;
          }

          return list;

        } else {
          // this.router.navigate(['/404']);
          this.router.navigate(['/home']);
          return null;
        }
      })
    );
  }
}
