import { Pipe, PipeTransform } from '@angular/core';
import { TimeAgoPipe } from 'time-ago-pipe';

@Pipe({
  name: 'fsDateAgo'
})
export class FsDateAgoPipe implements PipeTransform {

  constructor(
    private timeAgoPipe: TimeAgoPipe
  ) {}

  transform(value: firebase.firestore.Timestamp, args?: any): any {
    // return value.toDate().toDateString();
    return this.timeAgoPipe.transform(value.toDate().toDateString());
  }

}
