import { Component, Input, OnInit } from '@angular/core';
import { TasksService } from '../tasks.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase/app';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Task } from 'models/tasks.model';
import { SimpleUser } from 'models/users.model';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateTaskComponent implements OnInit {
  submitted: boolean;
  taskForm: FormGroup;
  name: FormControl;
  description: FormControl;
  dueDateFormControl: FormControl;

  @Input() projectId: string;
  @Input() parentListId: string;

  @Input() task: Task = {
    projectId: '',
    parentListId: '',
    name: '',
    description: '',
    dueDate: undefined,
    phase: {
      key: 'todo'
    },
    type: 'task'
  };

  dueDate: {
    config: {
      minDate: NgbDateStruct
    },
    val: NgbDateStruct
  } = {
    config: {
      minDate: {
        day: 1,
        month: 0,
        year: 0
      }
    },
    val: undefined
  };

  constructor(
    private activeModal: NgbActiveModal,
    private tasksService: TasksService,
    private toastr: ToastrService
  ) {

    this.submitted = false;
  }

  createFormControls() {
    this.name = new FormControl(this.task.name || '', [
      Validators.required,
    ]);
    this.description = new FormControl(this.task.description || '');
    this.dueDateFormControl = new FormControl(this.dueDate.val || {}, [
      Validators.required,
    ]);
  }

  createForm() {
    this.taskForm = new FormGroup({
      name: this.name,
      description: this.description,
      dueDateFormControl: this.dueDateFormControl,
    });
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();

    const today = new Date();
    this.dueDate.config.minDate = {
      // https://ng-bootstrap.github.io/#/components/datepicker/api#NgbDateStruct
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear()
    };

    // editing existing task
    if (this.task.id) {
      const taskDueDate = this.task.dueDate.toDate();
      const dueDateValue = {
        day: taskDueDate.getDate(),
        month: taskDueDate.getMonth() + 1,
        year: taskDueDate.getFullYear()
      };

      this.projectId = this.task.projectId;
      this.dueDate.val = { ...dueDateValue };
      this.dueDateFormControl.setValue({ ...dueDateValue });
    }
  }

  assignUser(user: SimpleUser | undefined) {
    this.task.assigneeObj = user ? user : null; // firestore doesn't accept undefined
  }

  save() {
    if (this.taskForm.status === 'INVALID') {
      this.submitted = true;

      return;
    }

    // TODO: all this date logic should move to it's own component...
    this.task.dueDate = firebase.firestore.Timestamp.fromDate(new Date(
      this.dueDateFormControl.value.year,
      this.dueDateFormControl.value.month - 1,
      this.dueDateFormControl.value.day
    ));

    // Create
    if (!this.task.id) {
      this.tasksService.create({
        ...this.task,
        name: this.name.value,
        description: this.description.value,
        projectId: this.projectId,
        parentListId: this.parentListId
      })
        .then(() => {
          this.closeModal();
        })
        .catch((err) => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error(err);
        });

    // Update
    } else {
      this.tasksService.update({
        ...this.task,
        name: this.name.value,
        description: this.description.value,
      })
        .then(() => {
          this.closeModal();
        })
        .catch((err) => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error(err);
        });
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}
