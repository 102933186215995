import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ListElements, List } from 'models/lists.model';
import { ListsService } from '../../lists/lists.service';
import { take, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { ToastrService } from 'ngx-toastr';
import { Task, SimpleTask } from 'models/tasks.model';
import { Item } from 'models/items.model';
import { ProjectsService } from '../../projects/projects.service';
import { Router } from '@angular/router';
import { ItemsService } from '../../items/items.service';
import { TasksService } from '../../tasks/tasks.service';

@Component({
  selector: 'app-move-resource-modal',
  templateUrl: './move-resource-modal.component.html',
  styleUrls: ['./move-resource-modal.component.scss']
})
export class MoveResourceModalComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() elements: ListElements = [];
  @Input() items: Item[] = [];

  mode: 'elements' | 'items' = 'elements';

  projectId: string;

  // List elements
  fromListIds: Set<string> = new Set();
  currentList: List;
  currentListId: string;
  listElements: ListElements = [];
  selectedListId: string;
  isLoadingListElements = true;

  // Items
  fromTasks: Set<SimpleTask> = new Set();
  currentTask: Task;
  currentTaskId: string;
  selectedTaskId: string;
  taskItems: Item[] = [];
  isLoadingTaskItems = true;

  constructor(
    private activeModal: NgbActiveModal,
    private itemsService: ItemsService,
    private listsService: ListsService,
    private projectsService: ProjectsService,
    private router: Router,
    private tasksService: TasksService,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    if (this.items.length) {
      this.mode = 'items';
      this.initItems();

    } else {
      this.mode = 'elements';
      this.initElements();
    }
  }

  initElements() {
    this.projectId = this.elements[0].projectId;
    this.currentListId = this.elements[0].parentListId;

    let fromId;
    let hasMultiFromIds = false;
    for (const element of this.elements) {
      this.fromListIds.add(element.parentListId);
      if (!fromId) {
        fromId = element.parentListId;
      }
      if (fromId !== element.parentListId) {
        hasMultiFromIds = true;
      }
    }

    if (!hasMultiFromIds) {
      this.initListElements();

    } else {
      this.initFromProject();
    }
  }

  initItems() {
    this.projectId = this.items[0].projectId;

    let fromId;
    let hasMultiFromIds = false;
    let hasUnassignedItem = false;
    for (const item of this.items) {
      if (item.taskObj) {
        this.fromTasks.add(item.taskObj);
        if (!fromId) {
          fromId = item.taskObj.id;
        }
        if (fromId !== item.taskObj.id) {
          hasMultiFromIds = true;
        }

      } else {
        hasUnassignedItem = true;
      }
    }

    // could be unassigned items
    if (!hasUnassignedItem) {
      this.currentTaskId = this.items[0].taskObj.id;
      this.initTaskItems();

    } else {
      this.initFromProject();
    }
  }

  initFromProject() {
    this.projectsService.getProject({ projectId: this.projectId })
      .pipe(take(1))
      .subscribe(project => {
        this.currentListId = project.listId;
        this.initListElements();
      });
  }

  initListElements(currentList?: List) {
    if (currentList) {
      this.currentList = currentList;
      this.currentListId = currentList.id;

    } else {
      this.listsService.getList({ listId: this.currentListId })
        .pipe(take(1))
        .subscribe(list => {
          this.currentList = list;
        });
    }

    this.selectedListId = this.currentListId;
    this.isLoadingListElements = true;

    this.listsService.getListElements({ listId: this.currentListId, projectId: this.projectId })
      // TODO: should be able to use take(1)...
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(listElements => {
        this.setListElements(listElements);
        this.isLoadingListElements = false;

      }, (err) => {
        this.toastr.error('Oops, something went wrong.');
        console.error(err);
      });
  }

  initTaskItems(currentTask?: Task) {
    if (currentTask) {
      this.currentTask = currentTask;
      this.currentTaskId = this.currentTask.id;

    } else {
      this.tasksService.getTask({ taskId: this.currentTaskId })
        .pipe(take(1))
        .subscribe(task => {
          this.currentTask = task;
        });
    }

    this.selectedTaskId = this.currentTaskId;
    this.isLoadingTaskItems = true;

    this.itemsService.getItems({ taskId: this.currentTaskId, projectId: this.projectId })
      .pipe(take(1))
      .subscribe(items => {
        this.setTaskItems(items);
        this.isLoadingTaskItems = false;

      }, (err) => {
        this.toastr.error('Oops, something went wrong.');
        console.error(err);
      });
  }

  setListElements(listElements: ListElements) {
    this.listElements = listElements.sort((a, b) => {
      return (a['name'].toLowerCase() < b['name'].toLowerCase()) ? -1 : 1;
    });
  }

  setTaskItems(items: Item[]) {
    this.taskItems = items.sort((a, b) => {
      return (a['name'].toLowerCase() < b['name'].toLowerCase()) ? -1 : 1;
    });
  }

  select(listElement: List | Task) {
    if (this.mode === 'elements') {
      if (listElement.type === 'task') {
        return;
      }

      // deselect and select parent
      if (this.selectedListId === listElement.id) {
        this.selectedListId = listElement.parentListId;

      } else {
        this.selectedListId = listElement.id;
      }

    } else if (this.mode === 'items') {
      if (listElement.type === 'list') {
        this.changeList(listElement);
        return;
      }
      this.selectedTaskId = listElement.id;
    }
  }

  changeList(listElement: List | Task) {
    if (listElement.type === 'list') {
      this.selectedTaskId = undefined;
      this.currentTaskId = undefined;
      this.currentTask = undefined;
      this.taskItems = undefined;

      this.initListElements(listElement);

    } else if (listElement.type === 'task') {
      this.selectedListId = undefined;
      this.currentListId = undefined;
      this.currentList = undefined;
      this.listElements = undefined;

      this.initTaskItems(listElement);
    }
  }

  back() {
    if (this.currentList) {
      this.currentListId = this.currentList.parentListId;

    } else if (this.currentTask) {
      this.currentListId = this.currentTask.parentListId;

      this.selectedTaskId = undefined;
      this.currentTaskId = undefined;
      this.currentTask = undefined;
      this.taskItems = undefined;
    }
    this.initListElements();
  }

  closePopup() {
    this.activeModal.dismiss();
  }

  canMoveToHere(listElement: List | Task) {
    if (this.mode === 'elements') {
      if (listElement.type === 'task') {
        return false;
      }

      if (this.fromListIds.has(this.currentListId)) {
        // cannot move list into itself
        for (const movingElement of this.elements) {
          if (movingElement.id === listElement.id) {
            return false;
          }
        }
      }

    } else if (this.mode === 'items') {
      return true;
    }

    return true;
  }

  confirmPopup() {
    const processing = this.toastr.info(`Moving...`, '', {
      positionClass: 'toast-bottom-center',
      timeOut: 0
    });

    if (this.mode === 'elements') {
      this.projectsService.moveElements({ elements: this.elements, newParentListId: this.selectedListId })
        .then(() => {
          this.toastr.clear(processing.toastId);
          this.activeModal.close();

          this.router.navigate(['/lists/', this.selectedListId]);
        })
        .catch((err) => {
          this.toastr.clear(processing.toastId);
          this.toastr.error('Oops, something went wrong.');
          console.error(err);
        });

    } else if (this.mode === 'items') {
      this.tasksService.getTask({ taskId: this.selectedTaskId })
        .pipe(take(1))
        .subscribe(task => {
          this.projectsService.moveItems({ items: this.items, newTask: task })
            .then(() => {
              this.toastr.clear(processing.toastId);
              this.activeModal.close();

              this.router.navigate(['/tasks/', this.selectedTaskId]);
            })
            .catch((err) => {
              this.toastr.clear(processing.toastId);
              this.toastr.error('Oops, something went wrong.');
              console.error(err);
            });
        });
    }
  }

}
