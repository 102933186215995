import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { AuthService } from '../../core/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { takeUntil, finalize } from 'rxjs/operators';
import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/storage';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { PopupService } from '../../popups/popup.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'models/users.model';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class UserSettingsComponent extends UnsubscribeOnDestroy implements OnInit {
  updateProfileForm: FormGroup;
  updatePasswordForm: FormGroup;
  currentPassword: FormControl;
  newPassword: FormControl;
  profileName: FormControl;
  profileEmail: FormControl;
  currentView: string;
  passwordSubmitted: boolean;
  profileSubmitted: boolean;
  user: User;

  profileImage: {
    ref: AngularFireStorageReference;
    task: AngularFireUploadTask;
    uploadProgress: Observable<number>;
    downloadURL: Observable<any>;
  } = {
    ref: undefined,
    task: undefined,
    uploadProgress: undefined,
    downloadURL: undefined
  };

  constructor(
    private afStorage: AngularFireStorage,
    private authService: AuthService,
    private popupService: PopupService,
    private toastr: ToastrService,
    private usersService: UsersService
  ) {
    super();

    this.passwordSubmitted = false;
    this.profileSubmitted = false;
  }

  createFormControls() {
    this.currentPassword = new FormControl('', [
      Validators.required
    ]);
    this.newPassword = new FormControl('', [
      Validators.required
    ]);
    this.profileName = new FormControl('', [
      Validators.required
    ]);
    this.profileEmail = new FormControl('', [
      Validators.email,
      Validators.required
    ]);
  }

  createForm() {
    this.updatePasswordForm = new FormGroup({
      currentPassword: this.currentPassword,
      newPassword: this.newPassword,
    });
    this.updateProfileForm = new FormGroup({
      profileName: this.profileName,
      profileEmail: this.profileEmail,
    });
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
    this.usersService.getUser({
      userId: this.authService.getUserId()
    })
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe((user) => {
      this.initData({user});
    });
  }

  private initData({user}: {user: User}) {
    this.user = user;
    this.profileName.setValue(user.name);
    this.profileEmail.setValue(user.email);
  }

  updateProfile() {
    if (this.user.email !== this.profileEmail.value) {
      this.popupService.prompt({
        message: 'To change your email, please confirm your password.',
        title: 'Confirm your password',
        field: 'Enter your password',
        fieldType: 'password'
      })
        .then(password => {
          this.authService.updateEmail(this.profileEmail.value, password)
            .then(() => {
              this.usersService.updateUser({
                // existing
                ...this.user,

                // updated
                name: this.profileName.value,
                email: this.profileEmail.value
              })
                .then(user => {
                  this.initData({user});
                  this.toastr.success('Your profile has been updated.');
                })
                .catch(err => {
                  this.toastr.error('Oops, something went wrong. Please try again.');
                  console.error('Something went wrong:', err);
                });
            })
            .catch(err => {
              this.toastr.error('Oops, something went wrong. Please try again.');
              console.error('Something went wrong:', err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.usersService.updateUser({
        // existing
        ...this.user,

        // updated
        name: this.profileName.value,
      })
        .then(user => {
          this.initData({user});
          this.toastr.success('Your profile has been updated.');
        })
        .catch(err => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error('Something went wrong:', err);
        });
    }
  }

  uploadProfileImage(event) {
    if (!event) {
      return;
    }
    const clearInput = () => {
      event.target.value = null;
    };

    const file = event.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      this.toastr.info(`This image is too large, please choose a new one that's less than 2MB.`);
      clearInput();
      return;
    }

    // TODO: chance of collision
    const fileName = `${Math.random().toString(36).substring(2)}_${encodeURI(file.name)}`;

    this.profileImage.ref = this.afStorage.ref(`profile_images/${fileName}`);
    this.profileImage.task = this.profileImage.ref.put(file);
    this.profileImage.uploadProgress = this.profileImage.task.percentageChanges();
    this.profileImage.task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          const downloadURL = this.profileImage.ref.getDownloadURL();
          downloadURL
            .pipe(takeUntil(this.componentDestroyed$))
            .subscribe(url => {
              this.usersService.updateUser({
                // existing
                ...this.user,

                // updated
                photoURL: url,
              })
                .then(user => {
                  // TODO: delete old photo from storage
                  this.initData({user});
                  clearInput();
                  this.toastr.success('Your profile image has been updated.');
                })
                .catch(err => {
                  this.toastr.error('Oops, something went wrong. Please try again.');
                  console.error('Something went wrong:', err);
                });
            });
          return this.profileImage.downloadURL = downloadURL;
        })
      )
      .subscribe();
  }

  resetPasswordValues() {
    [this.currentPassword, this.newPassword].forEach((control: FormControl) => control.setValue(''));
    this.passwordSubmitted = false;
  }

  updatePassword() {
    if (this.updatePasswordForm.status === 'INVALID') {
      this.passwordSubmitted = true;

      return;
    }

    this.authService.updatePassword(this.currentPassword.value, this.newPassword.value)
    .then(res => {
      this.toastr.success('Your password has been updated.');
      this.resetPasswordValues();
    })
    .catch(err => {
      this.toastr.error('Oops, something went wrong. Please try again.');
      console.error('change password error:', err);
      this.resetPasswordValues();
    });
  }

}
