import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInFormComponent } from './users/sign-in-form/sign-in-form.component';
import { SignUpFormComponent } from './users/sign-up-form/sign-up-form.component';
import { SignOutComponent } from './users/sign-out/sign-out.component';
import { AuthGuard } from './core/auth.guard';
import { ForgotPasswordComponent } from './users/forgot-password/forgot-password.component';
import { GuestGuard } from './core/guest.guard';
import { CompaniesResolverService } from './companies/companies-resolver.service';
import { HomeComponent } from './home/home.component';
import { ListComponent } from './lists/list/list.component';
import { ProjectsResolverService } from './projects/projects-resolver.service';
import { ListsResolverService } from './lists/lists-resolver.service';
import { ProjectComponent } from './projects/project/project.component';
import { TaskComponent } from './tasks/task/task.component';
import { TasksResolverService } from './tasks/tasks-resolver.service';
import { UserSettingsComponent } from './users/settings/settings.component';
import { AuthActionComponent } from './users/auth-action/auth-action.component';
import { ItemComponent } from './items/item/item.component';
import { ItemResolverService } from './items/item-resolver.service';
import { CompanyComponent } from './companies/company/company.component';
import { RoadmapComponent } from './lists/roadmap/roadmap.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/signin',
    pathMatch: 'full'
  },

  // Auth
  {
    path: 'signin',
    component: SignInFormComponent,
    canActivate: [GuestGuard]
  },
  {
    path: 'signup',
    component: SignUpFormComponent,
    canActivate: [GuestGuard]
  },
  {
    path: 'signout',
    component: SignOutComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [GuestGuard]
  },
  {
    path: 'auth-action',
    component: AuthActionComponent,
    // canActivate: [GuestGuard]
  },

  // Home
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },

  // User
  {
    path: 'settings',
    component: UserSettingsComponent,
    canActivate: [AuthGuard]
  },

  // Companies
  {
    path: 'companies/:companyId',
    component: CompanyComponent,
    canActivate: [AuthGuard],
    resolve: {
      company: CompaniesResolverService
    },
    children: [
      { path: '', redirectTo: 'projects', pathMatch: 'prefix' },
      { path: 'projects', component: CompanyComponent },
      { path: 'users', component: CompanyComponent }
    ]
  },

  // Projects
  {
    path: 'projects/:projectId',
    component: ProjectComponent,
    canActivate: [AuthGuard],
    resolve: {
      project: ProjectsResolverService
    },
    children: [
      { path: '', redirectTo: 'project', pathMatch: 'prefix' },
      { path: 'project', component: ProjectComponent },
      { path: 'items', component: ProjectComponent },
      { path: 'assignments', component: ProjectComponent },
      { path: 'activity', component: ProjectComponent }
    ]
  },

  // Lists
  {
    path: 'lists/:listId',
    component: ListComponent,
    canActivate: [AuthGuard],
    resolve: {
      list: ListsResolverService
    }
  },
  {
    path: 'lists/:listId/roadmap',
    component: RoadmapComponent,
    canActivate: [AuthGuard],
    resolve: {
      list: ListsResolverService
    }
  },

  // Tasks
  {
    path: 'tasks/:taskId',
    component: TaskComponent,
    canActivate: [AuthGuard],
    resolve: {
      task: TasksResolverService
    },
    children: [
      { path: '', redirectTo: 'items', pathMatch: 'prefix' },
      { path: 'items', component: TaskComponent },
      { path: 'activity', component: TaskComponent }
    ]
  },

  // Items
  {
    path: 'items/:itemId',
    component: ItemComponent,
    canActivate: [AuthGuard],
    resolve: {
      item: ItemResolverService
    }
  },

  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
