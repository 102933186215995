import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreService, BatchElement } from '../firestore.service';
import { AuthService } from '../core/auth.service';
import { Project } from 'models/projects.model';
import { User } from 'models/users.model';
import { List, ListElements } from 'models/lists.model';
import { Item } from 'models/items.model';
import { Task } from 'models/tasks.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(
    private authService: AuthService,
    private db: FirestoreService
  ) {

  }

  getProjects({ companyId, userId }: { companyId?: string, userId?: string }): Observable<Project[]> {
    const projectsCollectionRef = this.db.collectionWithIds$<Project>('projects', ref => {
      if (companyId) {
        return ref.where(`companyId`, '==', companyId);

      } else if (userId) {
        return ref.where(`users.${userId}`, '==', true);
      }
    });

    return projectsCollectionRef;
  }

  getProject({ projectId }: { projectId: string }): Observable<Project> {
    return this.db.doc$<Project>(`projects/${projectId}`)
      .pipe(
        map(project => {
          return { id: projectId, ...project } as Project;
        })
      );
  }

  getUsers({ projectId }: { projectId: string }): Observable<User[]> {
    return this.db.collectionWithIds$<User>(`projects/${projectId}/users`);
  }

  create(project: Project): Promise<void> {
    const projectRef = this.db.collection<Project>('projects').ref.doc();
    const listRef = this.db.collection<List>('lists').ref.doc();
    const userRef = this.db.doc(`users/${this.authService.getUserId()}`).ref;

    if (!project.users[this.authService.getUserId()]) {
      project.users[this.authService.getUserId()] = true;
    }

    return this.db.batch([
      {
        ref: projectRef,
        data: {
          ...project,
          listId: listRef.id
        },
        type: 'set'
      },
      {
        ref: listRef,
        data: {
          projectId: projectRef.id
        },
        type: 'set'
      },
      {
        ref: userRef,
        data: {
          projects: {
            [projectRef.id]: true
          }
        },
        type: 'set:merge'
      }
    ]);
  }

  moveElements({ elements, newParentListId }: { elements: ListElements, newParentListId: string }) {
    const batch: BatchElement[] = [];

    // TODO: should validate that no elements are moving into themselves...
    for (const element of elements) {
      const elementRef = this.db.doc(`${element.type}s/${element.id}`).ref;
      batch.push({
        ref: elementRef,
        data: {
          parentListId: newParentListId
        },
        type: 'update'
      });
    }

    return this.db.batch(batch);
  }

  deleteElements({ elements }: { elements: ListElements }) {
    const batch: BatchElement[] = [];

    for (const element of elements) {
      const elementRef = this.db.doc(`${element.type}s/${element.id}`).ref;
      batch.push({
        ref: elementRef,
        data: {},
        type: 'delete'
      });
    }

    return this.db.batch(batch);
  }

  moveItems({ items, newTask }: { items: Item[], newTask: Task }) {
    const batch: BatchElement[] = [];

    for (const item of items) {
      const itemRef = this.db.doc(`items/${item.id}`).ref;
      batch.push({
        ref: itemRef,
        data: {
          taskId: newTask.id,
          taskObj: {
            id: newTask.id,
            name: newTask.name
          }
        },
        type: 'update'
      });
    }

    return this.db.batch(batch);
  }

  deleteItems({ items }: { items: Item[] }) {
    const batch: BatchElement[] = [];

    for (const item of items) {
      const itemRef = this.db.doc(`items/${item.id}`).ref;
      batch.push({
        ref: itemRef,
        data: {},
        type: 'delete'
      });
    }

    return this.db.batch(batch);
  }

  update(project: Project): Promise<void> {
    // TODO: (DAS-4) high chance of other data being manipulated here
    return this.db.update<Project>(`projects/${project.id}`, project);
  }

  delete(project: Project): Promise<void> {
    // TODO: (DAS-8) also delete child data (lists, tasks, etc.)
    // TODO: (DAS-8) remove references, ie. users/{userId}.projects
    return this.db.delete(`projects/${project.id}`);
  }
}
