import { Component, Input, Output, EventEmitter } from '@angular/core';
import { List } from 'models/lists.model';

@Component({
  selector: 'app-list-list-item',
  templateUrl: './list-list-item.component.html',
  styleUrls: ['./list-list-item.component.scss']
})
export class ListListItemComponent {
  @Input() list: List;
  @Input() isChecked = false;

  @Output() changeList = new EventEmitter<List>();

  constructor(
  ) { }

  change() {
    this.changeList.emit(this.list);
  }
}
