import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule, FunctionsRegionToken } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import { CoreModule } from './core/core.module';
import { SignInFormComponent } from './users/sign-in-form/sign-in-form.component';
import { SignUpFormComponent } from './users/sign-up-form/sign-up-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignOutComponent } from './users/sign-out/sign-out.component';
import { ForgotPasswordComponent } from './users/forgot-password/forgot-password.component';
import { NavbarComponent } from './navbar/navbar.component';
import { CreateCompanyComponent } from './companies/create/create.component';
import { CompaniesService } from './companies/companies.service';
import { ProjectsService } from './projects/projects.service';
import { CreateProjectComponent } from './projects/create/create.component';
import { HomeComponent } from './home/home.component';
import { ProjectListItemComponent } from './projects/project-list-item/project-list-item.component';
import { ListComponent } from './lists/list/list.component';
import { ListsService } from './lists/lists.service';
import { CreateListComponent } from './lists/create/create.component';
import { CreateTaskComponent } from './tasks/create/create.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ProjectComponent } from './projects/project/project.component';
import { TaskComponent } from './tasks/task/task.component';
import { UserSettingsComponent } from './users/settings/settings.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AuthActionComponent } from './users/auth-action/auth-action.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ListListItemComponent } from './lists/list-list-item/list-list-item.component';
import { TaskListItemComponent } from './tasks/task-list-item/task-list-item.component';
import { EmailVerificationModalComponent } from './users/email-verification-modal/email-verification-modal.component';
import { UserAvatarComponent } from './users/user-avatar/user-avatar.component';
import { InviteUserModalComponent } from './companies/invite-user-modal/invite-user-modal.component';
import { AlertPopupComponent } from './popups/alert-popup/alert-popup.component';
import { ConfirmPopupComponent } from './popups/confirm-popup/confirm-popup.component';
import { PromptPopupComponent } from './popups/prompt-popup/prompt-popup.component';
import { ListProgressComponent } from './lists/list-progress/list-progress.component';
import { ManageResourceDropdownComponent } from './utilities/manage-resource-dropdown/manage-resource-dropdown.component';
import { CreateItemComponent } from './items/create/create.component';
import { ItemComponent } from './items/item/item.component';
import { FsDocPipe } from './pipes/fs-doc.pipe';
import { FsDatePipe } from './pipes/fs-date.pipe';
import { TimeAgoPipe } from 'time-ago-pipe';
import { FsDateAgoPipe } from './pipes/fs-date-ago.pipe';
import { AutosizeModule } from 'ngx-autosize';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DatePipe } from '@angular/common';
import { UserSearchComponent } from './projects/user-search/user-search.component';
import { HelpPanelComponent } from './utilities/help-panel/help-panel.component';
import { ItemCommentsComponent } from './item-comments/item-comments.component';
import { TaskCommentsComponent } from './task-comments/task-comments.component';
import { PlyrComponent } from './utilities/plyr/plyr.component';
import { UploadFormComponent } from './items/upload-form/upload-form.component';
import { FileDropDirective } from './items/file-drop.directive';
import { MoveResourceModalComponent } from './utilities/move-resource-modal/move-resource-modal.component';
import { ListElementsComponent } from './lists/list-elements/list-elements.component';
import { ActivityComponent } from './audit-logs/activity/activity.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ItemsComponent } from './items/items.component';
import { TabsComponent } from './utilities/tabs/tabs.component';
import { CompanyComponent } from './companies/company/company.component';
import { RoadmapComponent } from './lists/roadmap/roadmap.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInFormComponent,
    SignUpFormComponent,
    SignOutComponent,
    ForgotPasswordComponent,
    NavbarComponent,
    CreateCompanyComponent,
    CreateProjectComponent,
    HomeComponent,
    ProjectListItemComponent,
    ProjectComponent,
    ListComponent,
    CreateListComponent,
    CreateTaskComponent,
    BreadcrumbComponent,
    TaskComponent,
    UserSettingsComponent,
    AuthActionComponent,
    ListListItemComponent,
    TaskListItemComponent,
    EmailVerificationModalComponent,
    UserAvatarComponent,
    InviteUserModalComponent,
    AlertPopupComponent,
    ConfirmPopupComponent,
    PromptPopupComponent,
    ListProgressComponent,
    ManageResourceDropdownComponent,
    CreateItemComponent,
    ItemComponent,
    FsDocPipe,
    FsDatePipe,
    TimeAgoPipe,
    FsDateAgoPipe,
    UserSearchComponent,
    HelpPanelComponent,
    ItemCommentsComponent,
    TaskCommentsComponent,
    PlyrComponent,
    UploadFormComponent,
    FileDropDirective,
    MoveResourceModalComponent,
    ListElementsComponent,
    ActivityComponent,
    ToolbarComponent,
    ItemsComponent,
    TabsComponent,
    CompanyComponent,
    RoadmapComponent
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    // TODO: Consequently, if your web app handles sensitive information,
    // make sure to ask the user if they're on a trusted device before enabling persistence.
    // AngularFirestoreModule.enablePersistence(),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AppRoutingModule,
    BrowserModule,
    CoreModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({

    }),
    NgbModule.forRoot(),
    AutosizeModule,
    PdfViewerModule
  ],
  providers: [
    CompaniesService,
    ListsService,
    ProjectsService,
    DatePipe,
    { provide: FunctionsRegionToken, useValue: 'us-central1' }
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    AlertPopupComponent,
    ConfirmPopupComponent,
    CreateCompanyComponent,
    CreateItemComponent,
    CreateListComponent,
    CreateProjectComponent,
    CreateTaskComponent,
    EmailVerificationModalComponent,
    InviteUserModalComponent,
    PromptPopupComponent,
    MoveResourceModalComponent
  ]
})
export class AppModule { }
