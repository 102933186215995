import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'fsDate'
})
export class FsDatePipe implements PipeTransform {

  constructor(
    private datePipe: DatePipe
  ) {}

  transform(value: firebase.firestore.Timestamp, args?: any): any {
    return this.datePipe.transform(value.toDate(), args);
  }

}
