import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export abstract class UnsubscribeOnDestroy implements OnDestroy {
  protected componentDestroyed$: Subject<any>;

  constructor() {
    this.componentDestroyed$ = new Subject<void>();

    const f = this.ngOnDestroy;
    this.ngOnDestroy = () => {
      f();
      this.componentDestroyed$.next();
      this.componentDestroyed$.complete();
    };
  }

  public ngOnDestroy() {
    // no-op
  }

}
