import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { List } from 'models/lists.model';

export interface ListProgress {
  max: number;
  current: number;
}

@Component({
  selector: 'app-list-progress',
  templateUrl: './list-progress.component.html',
  styleUrls: ['./list-progress.component.scss']
})
export class ListProgressComponent implements OnInit, OnChanges {
  @Input() list: List;
  @Input() showLabel = false;
  @Input() height;
  @Input() showMeta = false;

  listProgress: ListProgress = {
    max: 0,
    current: 0
  };

  constructor() { }

  ngOnInit() {
    this.initProgress();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['list']) {
      this.initProgress();
    }
  }

  private initProgress() {
    if (!this.list || !this.list.childPhases) {
      this.listProgress.max = 0;
      return;
    }

    this.listProgress = {
      max: this.list.childPhases.todo + this.list.childPhases.done,
      current: this.list.childPhases.done
    };
  }

}
