import { Pipe, PipeTransform } from '@angular/core';
import { FirestoreService } from '../firestore.service';
import { Observable } from 'rxjs';

@Pipe({
  name: 'fsDoc'
})
export class FsDocPipe implements PipeTransform {

  constructor(
    private db: FirestoreService
  ) {}

  transform(value: any): Observable<any> {
    return this.db.doc$(value.path);
  }

}
