import { Component, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { ListsService } from '../../lists/lists.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Project } from 'models/projects.model';
import { List } from 'models/lists.model';
import { Tab } from 'src/app/utilities/tabs/tabs.component';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent extends UnsubscribeOnDestroy implements OnInit, AfterContentInit {
  project: Project;

  @ViewChild('projectTemplate') projectTemplate;
  @ViewChild('itemsTemplate') itemsTemplate;
  @ViewChild('assignmentsTemplate') assignmentsTemplate;
  @ViewChild('activityTemplate') activityTemplate;
  tabs: Tab[] = [];

  list: List;

  constructor(
    private listsService: ListsService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: { project: Project }) => {
        this.project = data.project;
        this.initList(this.project.listId);
      });
  }

  ngAfterContentInit() {
    this.tabs = [{
      name: 'project',
      displayName: 'Project',
      routerLink: ['./', 'project'],
      contentTemplate: this.projectTemplate
    }, {
      name: 'items',
      displayName: 'Items',
      routerLink: ['./', 'items'],
      contentTemplate: this.itemsTemplate
    }, {
      name: 'assignments',
      displayName: 'Assignments',
      routerLink: ['./', 'assignments'],
      contentTemplate: this.assignmentsTemplate
    }, {
      name: 'activity',
      displayName: 'Activity',
      routerLink: ['./', 'activity'],
      contentTemplate: this.activityTemplate
    }];
  }

  private initList(listId: string) {
    this.listsService.getList({ listId: listId })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(list => {
        this.list = list;
      }, (err) => {
        this.toastr.error('Oops, something went wrong.');
        console.error(err);
      });
  }

}
