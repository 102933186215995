import { Component, OnInit } from '@angular/core';
import { ListsService } from '../lists.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { Project } from 'models/projects.model';
import { List } from 'models/lists.model';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends UnsubscribeOnDestroy implements OnInit {
  list: List;

  constructor(
    private listsService: ListsService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: { list: List }) => {
        this.list = data.list;
        this.initList(this.list.id);
      });
  }

  private initList(listId: string) {
    this.listsService.getList({ listId: listId })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(list => {
        this.list = list;
      }, (err) => {
        this.toastr.error('Oops, something went wrong.');
        console.error(err);
      });
  }

}
