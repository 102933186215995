import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss']
})
export class SignUpFormComponent implements OnInit {
  submitted: boolean;
  signUpForm: FormGroup;
  name: FormControl;
  email: FormControl;
  password: FormControl;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.submitted = false;
  }

  createFormControls() {
    this.name = new FormControl('', Validators.required);
    this.email = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
    this.password = new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]);
  }

  createForm() {
    this.signUpForm = new FormGroup({
      name: this.name,
      email: this.email,
      password: this.password,
    });
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  signUp() {
    if (this.signUpForm.status === 'INVALID') {
      this.submitted = true;

      return;
    }

    const newUser = {
      name: this.name.value,
      email: this.email.value,
      password: this.password.value,
    };
    this.authService.signUp(newUser)
      .then(value => {
        [this.name, this.email, this.password].forEach((control: FormControl) => control.setValue(''));
        this.router.navigate(['/home']);
      })
      .catch(err => {
        this.toastr.error('Oops, something went wrong. Please try again.');
        console.error('Something went wrong:', err);
      });
  }

}
