import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { TasksService } from '../tasks/tasks.service';
import { FirestoreService } from '../firestore.service';
import { ListElements, List } from 'models/lists.model';

@Injectable({
  providedIn: 'root'
})
export class ListsService {

  constructor(
    private db: FirestoreService,
    private taskService: TasksService
  ) {

  }

  // Get lists child lists and child tasks
  getListElements({ listId, projectId }: { listId: string, projectId: string }): Observable<ListElements> {
    const listsCollectionRef = this.db.collectionWithIds$<List>('lists', ref => {
      return ref.where(`parentListId`, '==', listId).where('projectId', '==', projectId);
    });

    return listsCollectionRef
      .pipe(
        mergeMap(lists => {
          return this.taskService.getTasks({ listId, projectId })
            .pipe(
              map(tasks => [...lists, ...tasks])
            );
        })
      );
  }

  getList({ listId }: { listId: string }): Observable<List> {
    return this.db.doc$<List>(`lists/${listId}`)
      .pipe(
        map(list => {
          return { id: listId, ...list } as List;
        })
      );
  }

  create(list: List): Promise<firebase.firestore.DocumentReference> {
    return this.db.add<List>('lists', list);
  }

  update(list: List): Promise<void> {
    // TODO: (DAS-4) high chance of other data being manipulated here
    return this.db.update<List>(`lists/${list.id}`, list);
  }

  delete(list: List): Promise<void> {
    return this.db.delete(`lists/${list.id}`);
  }
}
