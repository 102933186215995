import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-prompt-popup',
  templateUrl: './prompt-popup.component.html',
  styleUrls: ['./prompt-popup.component.scss']
})
export class PromptPopupComponent implements OnInit {
  @Input() message: string;
  @Input() title: string;
  @Input() field: string;
  @Input() fieldType: string;

  fieldValue: string;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.fieldValue = '';
  }

  closePopup() {
    this.activeModal.dismiss();
  }

  confirmPopup() {
    this.activeModal.close();
  }

  submitPopup() {
    this.activeModal.close(this.fieldValue);
  }

}
