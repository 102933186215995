import { Injectable } from '@angular/core';
import { Upload } from '../../classes/upload';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private afStorage: AngularFireStorage
  ) {

  }

  pushUpload(upload: Upload) {
    upload.uploadTask = this.afStorage.upload(upload.bucketPath, upload.file);
    upload.percentage = upload.uploadTask.percentageChanges();
    upload.snapshot = upload.uploadTask.snapshotChanges();
  }
}
