import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm: FormGroup;
  email: FormControl;
  submitted: boolean;
  emailSent: Boolean = false;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService
  ) {
    this.submitted = false;
  }

  createFormControls() {
    this.email = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
  }

  createForm() {
    this.forgotPasswordForm = new FormGroup({
      email: this.email,
    });
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  resetValues() {
    this.email.setValue('');
    this.submitted = false;
  }

  forgotPassword() {
    if (this.forgotPasswordForm.status === 'INVALID') {
      this.submitted = true;

      return;
    }

    this.authService.sendForgotPassword(this.email.value)
      .then(value => {
        this.resetValues();
        this.emailSent = true;
      })
      .catch(err => {
        this.toastr.error('Oops, something went wrong. Please try again.');
        console.error('Something went wrong:', err);
      });
  }
}
