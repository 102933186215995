import { Injectable } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'models/users.model';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

export interface BatchElement {
  ref: firebase.firestore.DocumentReference;
  data: Object;
  type: 'set' | 'set:merge' | 'update' | 'update:merge' | 'delete';
}

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {
  writer: User;

  constructor(
    public afs: AngularFirestore,
    private afAuth: AngularFireAuth
  ) {
    this.afAuth.user
      .subscribe(fbUser => {
        if (fbUser) {
          this.doc$<User>(`users/${fbUser.uid}`)
            .subscribe(user => {
              this.writer = user;
            });

        } else {
          this.writer = undefined;
        }
      });
  }

  /// **************
  /// Get a Reference
  /// **************

  collection<T>(ref: CollectionPredicate<T>, queryFn?): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.afs.collection<T>(ref, queryFn) : ref;
  }

  doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.afs.doc<T>(ref) : ref;
  }

  /// **************
  /// Get Data
  /// **************

  doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref).snapshotChanges().pipe(
      map(doc => {
        return doc.payload.data() as T;
      })
    );
  }

  collection$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.collection(ref, queryFn).snapshotChanges().pipe(
      map(docs => {
        return docs.map(a => a.payload.doc.data()) as T[];
      })
    );
  }

  /// with Ids
  docWithIds$<T>(ref: DocPredicate<T>): Observable<any> {
    return this.doc(ref).snapshotChanges().pipe(
      map(action => {
        const data = action.payload.data() as Object;
        const id = action.payload.id;
        return { id, ...data };
      })
    );
  }

  collectionWithIds$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<any[]> {
    return this.collection(ref, queryFn).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data() as Object;
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  /// **************
  /// Write Data
  /// **************

  /// Firebase Server Timestamp
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  get deleteField() {
    return firebase.firestore.FieldValue.delete();
  }

  set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).set(
      this.populateUpdatedData(
        this.populateCreatedData(data)
      )
    );
  }

  // TODO: (DAS-4) don't allow certain fields to be modified by hack. ie. createdAt
  update<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).update(
      this.populateUpdatedData(data)
    );
  }

  delete<T>(ref: DocPredicate<T>) {
    return this.update(ref, this.populateDeletedData({})).then(() => {
      return this.doc(ref).delete();
    });
  }

  add<T>(ref: CollectionPredicate<T>, data: any): Promise<firebase.firestore.DocumentReference> {
    return this.collection(ref).add(
      this.populateUpdatedData(
        this.populateCreatedData(data)
      )
    );
  }

  /// If doc exists update, otherwise set
  upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const doc = this.doc(ref).snapshotChanges().pipe(
      take(1)
    ).toPromise();

    return doc.then(snap => {
      return snap.payload.exists ? this.update(ref, data) : this.set(ref, data);
    });
  }

  private populateCreatedData(data: any) {
    const created = {
      createdAt: this.timestamp
    };

    if (this.afAuth.auth.currentUser && this.writer) {
      created['createdBy'] = this.afAuth.auth.currentUser.uid;
      created['createdByObj'] = {
        id: this.afAuth.auth.currentUser.uid,
        email: this.writer.email,
        name: this.writer.name,
        photoURL: this.writer.photoURL
      };
    }

    return {
      ...data,
      ...created
    };
  }

  private populateUpdatedData(data: any) {
    const updated = {
      updatedAt: this.timestamp
    };

    if (this.afAuth.auth.currentUser && this.writer) {
      updated['updatedBy'] = this.afAuth.auth.currentUser.uid;
      updated['updatedByObj'] = {
        id: this.afAuth.auth.currentUser.uid,
        email: this.writer.email,
        name: this.writer.name,
        photoURL: this.writer.photoURL
      };
    }

    return {
      ...data,
      ...updated
    };
  }

  private populateDeletedData(data: any) {
    const deleted = {
      deletedAt: this.timestamp
    };

    if (this.afAuth.auth.currentUser && this.writer) {
      deleted['deletedBy'] = this.afAuth.auth.currentUser.uid;
      deleted['deletedByObj'] = {
        id: this.afAuth.auth.currentUser.uid,
        email: this.writer.email,
        name: this.writer.name,
        photoURL: this.writer.photoURL
      };
    }

    return {
      ...data,
      ...deleted
    };
  }

  /// **************
  /// Inspect Data
  /// **************

  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    this.doc(ref).snapshotChanges()
      .pipe(
        take(1),
        tap(d => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Document in ${tock}ms`, d);
        })
      )
      .subscribe();
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.collection(ref).snapshotChanges()
      .pipe(
        take(1),
        tap(c => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Collection in ${tock}ms`, c);
        })
      )
      .subscribe();
  }

  /// **************
  /// Create and read doc references
  /// **************

  /// create a reference between two documents
  connect(host: DocPredicate<any>, key: string, doc: DocPredicate<any>): Promise<void> {
    return this.doc(host).update({ [key]: this.doc(doc).ref });
  }

  /// returns a documents references mapped to AngularFirestoreDocument
  docWithRefs$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc$(ref).pipe(
      map(doc => {
        for (const k of Object.keys(doc)) {
          if (doc[k] instanceof firebase.firestore.DocumentReference) {
            doc[k] = this.doc(doc[k].path);
          }
        }
        return doc;
      })
    );
  }

  /// **************
  /// Atomic batch
  /// **************

  batch(batchElements: BatchElement[]): Promise<void> {
    const batch = firebase.firestore().batch();
    const batchDeleteUpdates = firebase.firestore().batch();

    for (const batchElement of batchElements) {
      switch (batchElement.type) {
        case 'set':
          batch.set(
            batchElement.ref,
            this.populateUpdatedData(
              this.populateCreatedData(batchElement.data)
            )
          );
          break;

        case 'set:merge':
        case 'update:merge':
          batch.set(
            batchElement.ref,
            this.populateUpdatedData(batchElement.data),
            { merge: true }
          );
          break;

        case 'update':
          batch.update(
            batchElement.ref,
            this.populateUpdatedData(batchElement.data)
          );
          break;

        case 'delete':
          // deletedAt and deletedBy needs to be populated for auditlog/activity
          batchDeleteUpdates.update(
            batchElement.ref,
            this.populateDeletedData({})
          );
          batch.delete(batchElement.ref);
          break;
      }
    }

    /// commit operations
    return batchDeleteUpdates.commit().then(() => {
      return batch.commit();
    });
  }
}
