import { AngularFireUploadTask } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { SimpleTask } from 'models/tasks.model';

export class Upload {

  $key: string;
  projectId: string;
  taskObj?: SimpleTask;
  uploadTask: AngularFireUploadTask;
  file: File;
  bucketPath: string;
  name: string;
  url: string;
  percentage: Observable<number>;
  snapshot: Observable<any>;
  createdAt: Date = new Date();

  constructor({file, projectId, taskObj}: {file: File, projectId: string, taskObj?: SimpleTask}) {
    this.file = file;
    this.projectId = projectId;
    this.taskObj = taskObj;
    this.name = `${Math.random().toString(36).substring(2)}_${encodeURI(file.name)}`;
    this.bucketPath = `projects/${projectId}/items/${this.name}`;
  }
}
