import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AuditLogsService } from '../audit-logs.service';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { takeUntil } from 'rxjs/operators';
import { AuditLog } from 'models/audit-logs.model';
import { ToastrService } from 'ngx-toastr';
import { Task } from '../../../../models/tasks.model';
import { Item } from '../../../../models/items.model';
import { SimpleUser } from '../../../../models/users.model';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
  @Input() projectId?: string;
  @Input() task?: Task;
  @Input() item?: Item;
  @Input() hideFilter = false;

  auditLogs: AuditLog[] = [];
  filteredAuditLogs: AuditLog[] = [];
  filteredUser: SimpleUser;
  isLoadingAuditLogs = true;

  constructor(
    private auditLogsService: AuditLogsService,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.isLoadingAuditLogs = true;

    if (this.task) {
      this.projectId = this.task.projectId;

    } else if (this.item) {
      this.projectId = this.item.projectId;
    }

    const auditLogParams: { projectId: string, docId?: string, docParentId?: string, docType?: string } = {
      projectId: this.projectId
    };

    if (this.task) {
      auditLogParams.docId = this.task.id;
      auditLogParams.docType = 'task';
    }

    if (this.item) {
      auditLogParams.docId = this.item.id;
      auditLogParams.docType = 'item';
    }

    this.auditLogsService.getAuditLogs(auditLogParams)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(auditLogs => {
        this.isLoadingAuditLogs = false;

        this.auditLogs = auditLogs.sort((a, b) => {
          return (a.changedAt.seconds > b.changedAt.seconds) ? -1 : 1;
        });

        this.filteredAuditLogs = [...this.auditLogs];
      }, (err) => {
        this.toastr.error('Oops, something went wrong.');
        console.error(err);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['projectId'] || changes['task']) {
      this.ngOnInit();
    }
  }

  filterByUser(user: SimpleUser | undefined) {
    this.filteredUser = user;

    this.filteredAuditLogs = [...this.auditLogs].filter(element => {
      if (!user) {
        return true;
      }

      return element.changedByObj.id === user.id;
    });
  }

}
