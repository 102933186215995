import { Component, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { TasksService } from '../tasks.service';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { ToastrService } from 'ngx-toastr';
import { Task } from 'models/tasks.model';
import { User } from 'models/users.model';
import { Tab } from 'src/app/utilities/tabs/tabs.component';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss']
})
export class TaskComponent extends UnsubscribeOnDestroy implements OnInit, AfterContentInit {
  task: Task;

  @ViewChild('itemsTemplate') itemsTemplate;
  @ViewChild('activityTemplate') activityTemplate;
  tabs: Tab[] = [];

  constructor(
    private route: ActivatedRoute,
    private tasksService: TasksService,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: { task: Task }) => {
        this.task = data.task;
        this.initTask();
      });
  }

  ngAfterContentInit() {
    this.tabs = [{
      name: 'items',
      displayName: 'Items',
      routerLink: ['./', 'items'],
      contentTemplate: this.itemsTemplate
    }, {
      name: 'activity',
      displayName: 'Activity',
      routerLink: ['./', 'activity'],
      contentTemplate: this.activityTemplate
    }];
  }

  private initTask() {
    this.tasksService.getTask({ taskId: this.task.id })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(task => {
        this.task = task;
      });
  }

  assignUser(user: User) {
    if (this.task.assigneeObj && this.task.assigneeObj.id === user.id) {
      return;
    }

    this.tasksService.update({
      ...this.task,
      assigneeObj: user
    })
      .then(() => {
        this.toastr.success('Task saved.');
      })
      .catch(err => {
        console.error(err);
        this.toastr.error('Oops, something went wrong. Please try again.');
      });
  }

  changeTaskPhase(key: 'todo' | 'done') {
    this.tasksService.update({
      ...this.task,
      phase: {
        key
      }
    })
      .then(() => {
        this.toastr.success('Task saved.');
      })
      .catch(err => {
        console.error(err);
        this.toastr.error('Oops, something went wrong. Please try again.');
      });
  }

}
