import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../core/auth.service';
import { FirestoreService } from '../firestore.service';
import { Company } from 'models/companies.model';
import { User } from 'models/users.model';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(
    private afFunctions: AngularFireFunctions,
    private authService: AuthService,
    private db: FirestoreService
  ) {
  }

  getCompanies(): Observable<Company[]> {
    return this.db.collectionWithIds$<Company>('companies', ref => {
      return ref.where(`users.${this.authService.getUserId()}`, '==', true);
    });
  }

  getCompany({ companyId }: { companyId: string }): Observable<Company> {
    return this.db.doc$<Company>(`companies/${companyId}`)
      .pipe(
        map(company => {
          return { id: companyId, ...company } as Company;
        })
      );
  }

  getCompanyUsers({ companyId }: { companyId: string }) {
    return this.db.collectionWithIds$<User>(`companies/${companyId}/users`);
  }

  create(company: Company): Promise<void> {
    const companyRef = this.db.collection<Company>('companies').ref.doc();
    const userRef = this.db.doc(`users/${this.authService.getUserId()}`).ref;

    if (!company.users[this.authService.getUserId()]) {
      company.users[this.authService.getUserId()] = true;
    }

    return this.db.batch([
      {
        ref: companyRef,
        data: {
          ...company
        },
        type: 'set'
      },
      {
        ref: userRef,
        data: {
          companies: {
            [companyRef.id]: true
          }
        },
        type: 'set:merge'
      }
    ]);
  }

  update(company: Company): Promise<void> {
    // TODO: (DAS-4) high chance of other data being manipulated here
    return this.db.update<Company>(`companies/${company.id}`, company);
  }

  delete(company: Company): Promise<void> {
    // TODO: also delete child data (projects, lists, tasks, etc.)
    // TODO: remove references, ie. users/{userId}.companies
    return this.db.delete(`companies/${company.id}`);
  }

  removeUser({ userId, companyId }: { userId: string, companyId: string }) {
    return this.afFunctions.httpsCallable('companiesRemoveUser')({ userId: userId, companyId: companyId });
  }
}
