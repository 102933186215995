import { Component, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { ProjectsService } from '../../projects/projects.service';
import { ActivatedRoute } from '@angular/router';
import { CompaniesService } from '../companies.service';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateProjectComponent } from '../../projects/create/create.component';
import { InviteUserModalComponent } from '../invite-user-modal/invite-user-modal.component';
import { UserInvitesService } from '../../users/user-invites.service';
import { AuthService } from '../../core/auth.service';
import { PopupService } from '../../popups/popup.service';
import { Company } from 'models/companies.model';
import { Project } from 'models/projects.model';
import { User, UserInvite } from 'models/users.model';
import { Tab } from '../../utilities/tabs/tabs.component';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent extends UnsubscribeOnDestroy implements OnInit, AfterContentInit {
  private sortProperty = 'name';

  @ViewChild('projectsTemplate') projectsTemplate;
  @ViewChild('usersTemplate') usersTemplate;
  tabs: Tab[] = [];

  company: Company;
  projects: Project[] = [];
  isLoadingProjects = true;
  users: User[] = [];
  userInvites: UserInvite[] = [];
  sessionUserId: string;

  constructor(
    private authService: AuthService,
    private companiesService: CompaniesService,
    private modalService: NgbModal,
    private popupService: PopupService,
    private projectsService: ProjectsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private userInvitesService: UserInvitesService
  ) {
    super();
  }

  ngOnInit() {
    this.sessionUserId = this.authService.getUserId();

    this.route.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: { company: Company }) => {
        this.company = data.company;

        this.projectsService.getProjects({ companyId: this.company.id })
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(projects => {
            this.setProjects(projects);
            this.isLoadingProjects = false;

          }, error => {
            this.toastr.error('Oops, something went wrong.');
            console.error(error);
          });

        this.companiesService.getCompanyUsers({ companyId: this.company.id })
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(users => {
            this.setUsers(users);
          }, error => {
            this.toastr.error('Oops, something went wrong.');
            console.error(error);
          });

        this.userInvitesService.getCompanyUserInvites({ companyId: this.company.id })
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(userInvites => {
            this.userInvites = userInvites;
          }, error => {
            this.toastr.error('Oops, something went wrong.');
            console.error(error);
          });
      });
  }

  ngAfterContentInit() {
    this.tabs = [{
      name: 'projects',
      displayName: 'Projects',
      routerLink: ['./', 'projects'],
      contentTemplate: this.projectsTemplate
    }, {
      name: 'users',
      displayName: 'Users',
      routerLink: ['./', 'users'],
      contentTemplate: this.usersTemplate
    }];
  }

  private setProjects(projects: Project[]): void {
    this.projects = projects.sort((a, b) => {
      return (a[this.sortProperty].toLowerCase() < b[this.sortProperty].toLowerCase()) ? -1 : 1;
    });
  }

  private setUsers(users: User[]): void {
    this.users = users.sort((a, b) => {
      return (a[this.sortProperty].toLowerCase() < b[this.sortProperty].toLowerCase()) ? -1 : 1;
    });
  }

  addProject() {
    const modalRef = this.modalService.open(CreateProjectComponent);
    modalRef.componentInstance.company = this.company;
  }

  addUser() {
    const modalRef = this.modalService.open(InviteUserModalComponent);
    modalRef.componentInstance.company = this.company;
  }

  removeUser(user: User) {
    this.popupService.confirm({ message: `Are you sure you want to remove ${user.name}?`, title: 'Remove user' })
      .then(() => {
        const processing = this.toastr.info(`Removing ${user.name}...`, '', {
          positionClass: 'toast-bottom-center',
          timeOut: 0
        });

        this.companiesService.removeUser({ userId: user.id, companyId: this.company.id })
          .subscribe(() => {
          }, err => {
            this.toastr.error('Oops, something went wrong. Please try again.');
            console.error(err);
          }, () => {
            this.toastr.clear(processing.toastId);
          });
      })
      .catch(() => { });
  }

  deleteInvite(userInvite: UserInvite) {
    this.userInvitesService.deleteCompanyUserInvite(this.company.id, userInvite)
      .then(() => { })
      .catch(err => {
        this.toastr.error('Oops, something went wrong. Please try again.');
        console.error(err);
      });
  }

}
