import { Component, OnInit } from '@angular/core';
import { ListsService } from '../lists.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { Project } from 'models/projects.model';
import { List, ListElements } from 'models/lists.model';
import { ProjectsService } from 'src/app/projects/projects.service';
import { zip } from 'rxjs';
import { Task } from 'models/tasks.model';
import * as moment from 'moment';
import { ListProgress } from '../list-progress/list-progress.component';

interface RoadmapElement extends ListProgress {
  element: List | Task;
  width: number;
  offset: number;
}

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.scss']
})
export class RoadmapComponent extends UnsubscribeOnDestroy implements OnInit {
  project: Project;

  list: List;
  listProgress: ListProgress;
  listElements: ListElements = [];
  roadmapElements: RoadmapElement[] = [];

  dates: Date[] = [];
  today: Date = new Date();

  constructor(
    private listsService: ListsService,
    private projectsService: ProjectsService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: { list: List }) => {
        this.list = data.list;
        this.initSubscriptions();
      });
  }

  private initSubscriptions() {
    zip(
      this.listsService.getList({ listId: this.list.id }),
      this.listsService.getListElements({ listId: this.list.id, projectId: this.list.projectId }),
      this.projectsService.getProject({ projectId: this.list.projectId })
    )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(res => {
        this.list = res[0];
        this.listElements = res[1];
        this.project = res[2];

        this.listProgress = this.getProgress({...this.list, type: 'list'});
        this.initDates();
        this.initRoadmapElements();
      }, (err) => {
        this.toastr.error('Oops, something went wrong.');
        console.error(err);
      });
  }

  private initRoadmapElements() {
    this.roadmapElements = [];

    for (const element of this.listElements) {
      this.roadmapElements.push({
        // List | Task
        element: element,

        // ListProgress
        ...this.getProgress(element),

        // Roadmap
        width: this.getWidth(element),
        offset: this.getOffset(element)
      });
    }
  }

  private initDates() {
    const startDate = moment(this.list.createdAt.toDate()).hours(0).minutes(0).seconds(0);
    const endDate = moment(this.list.lastDueDate.toDate()).hours(23).minutes(59).seconds(59);
    const dates: Date[] = [];

    let currentDate = startDate;
    while (currentDate <= endDate) {
      dates.push(currentDate.toDate());
      currentDate = moment(currentDate).add(1, 'days');
    }

    this.dates = dates;
  }

  private getProgress(element: List | Task): ListProgress {
    return {
      max: element.type === 'list' && element.childPhases ? element.childPhases.todo + element.childPhases.done : 0,
      current: element.type === 'list' && element.childPhases ? element.childPhases.done : 0,
    };
  }

  isToday(d1: Date) {
    return this.isSameDay(d1, this.today);
  }

  isSameDay(d1: Date, d2: Date) {
    return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
  }

  private getWidth(element: Task | List) {
    const startDate = moment(element.createdAt.toDate()).hours(0).minutes(0).seconds(0);
    let endDate = startDate;
    if (element.type === 'task' && element.dueDate) {
      endDate = moment(element.dueDate.toDate()).add(1, 'days').hours(23).minutes(59).seconds(59);

    } else if (element.type === 'list' && element.lastDueDate) {
      endDate = moment(element.lastDueDate.toDate()).add(1, 'days').hours(23).minutes(59).seconds(59);
    }

    return endDate.diff(startDate, 'days') / this.dates.length * 100;
  }

  private getOffset(element: Task | List) {
    const listStartDate = moment(this.dates[0]).hours(0).minutes(0).seconds(0);
    const startDate = moment(element.createdAt.toDate()).hours(23).minutes(59).seconds(59);

    return startDate.diff(listStartDate, 'days') / this.dates.length * 100;
  }

}
