import { Component, OnInit, Input, TemplateRef, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RouterEvent } from '@angular/router';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from 'src/classes/unsubscribe-on-destroy';

export interface Tab {
  name: string;
  displayName: string;
  routerLink: string[];
  contentTemplate: TemplateRef<any>;
}

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
  @Input() tabs: Tab[] = [];

  currentTab: Tab;
  currentRouteUrl: string;

  constructor(
    private router: Router
  ) {
    super();

    this.currentRouteUrl = this.router.url;
    this.changeTabsByUrl();

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof NavigationEnd),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    ).subscribe((event) => {
      this.currentRouteUrl = event.url;
      this.changeTabsByUrl();
    });
  }

  ngOnInit() {
    if (!this.tabs.length) {
      return;
    }

    this.changeTabsByUrl();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tabs']) {
      this.ngOnInit();
    }
  }

  changeTabsByUrl() {
    if (this.currentRouteUrl) {
      for (const tab of this.tabs) {
        if (this.currentRouteUrl.endsWith(`/${tab.name}`)) {
          this.currentTab = tab;
          break;
        }
      }
    }

    if (!this.currentTab && this.tabs.length) {
      this.currentTab = this.tabs[0];
    }
  }

  changeTab(tab: Tab) {
    this.currentTab = tab;
  }

}
