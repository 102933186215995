import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { List } from 'models/lists.model';
import { Task } from 'models/tasks.model';
import { Item } from 'models/items.model';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {

  private selectedElements$ = new Subject<Set<List | Task | Item>>();

  constructor() { }

  setSelectedElements(elements: Set<List | Task | Item>) {
    this.selectedElements$.next(elements);
  }

  getSelectedElements(): Observable<Set<List | Task | Item>> {
    return this.selectedElements$.asObservable();
  }
}
