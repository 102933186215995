import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Project } from 'models/projects.model';
import { Task } from 'models/tasks.model';
import { UnsubscribeOnDestroy } from 'src/classes/unsubscribe-on-destroy';
import { Upload } from 'src/classes/upload';
import { Item } from 'models/items.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MoveResourceModalComponent } from '../utilities/move-resource-modal/move-resource-modal.component';
import { ToastrService } from 'ngx-toastr';
import { ItemsService } from './items.service';
import { takeUntil } from 'rxjs/operators';
import { ToolbarService } from '../toolbar/toolbar.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.scss']
})
export class ItemsComponent extends UnsubscribeOnDestroy implements OnInit, OnChanges {
  @Input() project?: Project;
  @Input() task?: Task;

  projectId: string;

  items: Item[] = [];
  selectedItems: Set<Item> = new Set();
  isLoadingItems = true;
  itemUploads: Upload[] = [];

  constructor(
    private itemsService: ItemsService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private toolbarService: ToolbarService
  ) {
    super();
  }

  ngOnInit() {
    this.resetSelectedItems();

    if (this.project) {
      this.projectId = this.project.id;

    } else if (this.task) {
      this.projectId = this.task.projectId;
    }

    this.initItems();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['project'] || changes['task']) {
      this.ngOnInit();
    }
  }

  private initItems() {
    const params = {
      projectId: this.projectId
    };

    if (this.task) {
      params['taskId'] = this.task.id;
    }

    this.itemsService.getItems(params)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(items => {
        this.setItems(items);
        this.isLoadingItems = false;
      }, (error) => {
        this.toastr.error('Oops, something went wrong.');
        console.error(error);
      });
  }

  private setItems(items: Item[]): void {
    this.items = items.sort((a, b) => {
      return (a['name'].toLowerCase() < b['name'].toLowerCase()) ? -1 : 1;
    });
  }

  resetSelectedItems() {
    this.selectedItems = new Set();
    this.toolbarService.setSelectedElements(this.selectedItems);
  }

  onUploadsChange(uploads: Upload[]) {
    this.itemUploads = uploads;
  }

  changeSelectedItems(item: Item) {
    if (this.selectedItems.has(item)) {
      this.selectedItems.delete(item);

    } else {
      this.selectedItems.add(item);
    }

    this.toolbarService.setSelectedElements(this.selectedItems);
  }

  selectAll(el: any) {
    if (el && el.target && el.target.checked) {
      this.selectedItems = new Set(this.items);
      this.toolbarService.setSelectedElements(this.selectedItems);

    } else {
      this.resetSelectedItems();
    }
  }

  moveItems() {
    const modalRef = this.modalService.open(MoveResourceModalComponent);
    modalRef.componentInstance.items = Array.from(this.selectedItems);
    modalRef.result.then(() => {
      this.resetSelectedItems();
    });
  }

  getIconName(item: Item): string {
    if (item.type === 'file' && item.fileMeta) {

      // https://www.freeformatter.com/mime-types-list.html
      // TODO: better if it was just a map
      switch (item.fileMeta.mimetype) {
        case 'application/pdf':
          return 'pdf-icon';

        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/msword':
        case 'application/vnd.oasis.opendocument.text':
        case 'text/plain':
        case 'text/markdown':
        case 'application/rtf':
        case 'text/rtf':
          return 'doc-icon';

        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'text/csv':
        case 'application/vnd.oasis.opendocument.spreadsheet':
        case 'application/octet-stream':
          return 'xls-icon';

        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        case 'application/vnd.oasis.opendocument.presentation':
        case 'application/vnd.ms-powerpoint':
          return 'ppt-icon';

        case 'application/zip':
          return 'zip-icon';

        case 'application/javascript':
        case 'application/json':
        case 'text/html':
          return 'code-icon';
      }

      if (item.fileMeta.mimetype.indexOf('image/') === 0) {
        return 'image-icon';
      }

      if (item.fileMeta.mimetype.indexOf('video/') === 0) {
        return 'video-icon';
      }

      if (item.fileMeta.mimetype.indexOf('audio/') === 0) {
        return 'audio-icon';
      }
    }

    if (item.type === 'file') {
      return 'unknown';
    }

    if (item.type === 'url') {
      return 'url-icon';
    }

    return 'unknown';
  }

}
