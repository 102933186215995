import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';

@Component({
  selector: 'app-auth-action',
  templateUrl: './auth-action.component.html',
  styleUrls: ['./auth-action.component.scss']
})
export class AuthActionComponent extends UnsubscribeOnDestroy implements OnInit {
  password: string;
  validCode = false;
  loading = true;
  mode: string;
  code: string;
  isAuthenticated = false;

  restoredEmail: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.mode = this.activatedRoute.snapshot.queryParams['mode'];
    this.code = this.activatedRoute.snapshot.queryParams['oobCode'];

    this.authService.getAuthUser()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((user) => {
        this.isAuthenticated = !!user;
      });

    switch (this.mode) {
      case 'resetPassword':
        this.authService.verifyPasswordResetCode(this.code)
          .then(() => {
            this.validCode = true;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
        break;

      case 'recoverEmail':
        this.authService.verifyRecoverEmailCode(this.code)
          .then(info => {
            // TODO: (DAS-9) need to keep this in sync w/ the users/{userId} doc in firestore
            this.restoredEmail = info['data']['email'];
            this.authService.applyActionCode(this.code);
            this.validCode = true;
            this.loading = false;

            // TODO: (DAS-1) only doing this because the modal doesn't dismiss
            window.location.href = '/home';
          })
          .catch((err) => {
            this.loading = false;
          });
        break;

      case 'verifyEmail':
        this.authService.applyActionCode(this.code)
          .then(() => {
            this.validCode = true;
            this.loading = false;

            // TODO: (DAS-1) only doing this because the modal doesn't dismiss
            window.location.href = '/home';
          })
          .catch(err => {
            this.loading = false;
          });
        break;
    }
  }

  resetPassword() {
    this.authService.confirmPasswordReset(this.code, this.password)
      .then(value => {
        this.toastr.success('Your password has been reset.');
        this.password = '';
        this.router.navigate(['signin']);
      })
      .catch(err => {
        this.toastr.error('Oops, something went wrong. Please try again.');
        console.error('Something went wrong:', err);
      });
  }

}
