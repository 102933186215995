import { Component, OnInit } from '@angular/core';
import { CompaniesService } from '../companies/companies.service';
import { ProjectsService } from '../projects/projects.service';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';
import { ToastrService } from 'ngx-toastr';
import { combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UnsubscribeOnDestroy } from '../../classes/unsubscribe-on-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateCompanyComponent } from '../companies/create/create.component';
import { UsersService } from '../users/users.service';
import { UserInvitesService } from '../users/user-invites.service';
import { Company, CompanyAndProjects } from 'models/companies.model';
import { Project } from 'models/projects.model';
import { UserInvite } from 'models/users.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends UnsubscribeOnDestroy implements OnInit {
  private sortProperty = 'name';

  companiesAndProjects: CompanyAndProjects[] = [];
  isLoadingCompanies = true;
  userInvites: UserInvite[] = [];

  constructor(
    private authService: AuthService,
    private companiesService: CompaniesService,
    private modalService: NgbModal,
    private projectsService: ProjectsService,
    private router: Router,
    private toastr: ToastrService,
    private usersService: UsersService,
    private userInvitesService: UserInvitesService
  ) {
    super();
  }

  ngOnInit() {
    combineLatest(
      this.companiesService.getCompanies(),
      this.projectsService.getProjects({ userId: this.authService.getUserId() })
    )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(([companies, projects]) => {
        this.setCompanies(companies, projects);
        this.isLoadingCompanies = false;

      }, err => {
        this.toastr.error('Oops, something went wrong.');
        console.error(err);
      });

    this.usersService.getUser({ userId: this.authService.getUserId() })
      .pipe(take(1))
      .subscribe(user => {
        this.userInvitesService.getUserInvites({ email: user.email })
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(userInvites => {
            this.userInvites = userInvites;

          }, err => {
            console.error(err);
          });
      });
  }

  private setCompanies(companies: Company[], projects: Project[]): void {
    this.companiesAndProjects = [];
    const sortedCompanies: Company[] = companies.sort((a, b) => {
      return (a[this.sortProperty].toLowerCase() < b[this.sortProperty].toLowerCase()) ? -1 : 1;
    });

    for (const company of sortedCompanies) {
      this.companiesAndProjects.push({
        company,
        projects: projects.filter(project => project.companyId === company.id).sort((a, b) => {
          return (a[this.sortProperty].toLowerCase() < b[this.sortProperty].toLowerCase()) ? -1 : 1;
        })
      });
    }
  }

  addCompany() {
    this.modalService.open(CreateCompanyComponent);
  }

  selectCompany(company: Company) {
    this.router.navigate(['/companies/', company.id]);
  }

  joinInvite(userInvite: UserInvite) {
    const processing = this.toastr.info(`Joining ${userInvite.docName}...`, '', {
      positionClass: 'toast-bottom-center',
      timeOut: 0
    });

    // TODO: need to show some kind of "loading..." message
    this.userInvitesService.joinFromInvite(userInvite)
      .subscribe(() => {
      }, err => {
        this.toastr.error('Oops, something went wrong.');
        console.error(err);
      }, () => {
        this.toastr.clear(processing.toastId);
      });
  }

}
