import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ItemsService } from './items.service';
import { Item } from 'models/items.model';

@Injectable({
  providedIn: 'root'
})
export class ItemResolverService implements Resolve<Item> {

  constructor(
    private itemsService: ItemsService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Item> {
    let itemId = route.paramMap.get('itemId');
    if (!itemId) {
      itemId = route.queryParamMap.get('itemId');
    }

    return this.itemsService.getItem({itemId}).pipe(
      take(1),
      map(item => {
        if (item) {
          return item;

        } else {
          // this.router.navigate(['/404']);
          this.router.navigate(['/home']);
          return null;
        }
      })
    );
  }
}
