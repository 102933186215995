import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ProjectsService } from './projects.service';
import { Project } from 'models/projects.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectsResolverService implements Resolve<Project> {

  constructor(
    private projectsService: ProjectsService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> {
    let projectId = route.paramMap.get('projectId');
    if (!projectId) {
      projectId = route.queryParamMap.get('projectId');
    }

    return this.projectsService.getProject({projectId}).pipe(
      take(1),
      map(project => {
        if (project) {
          return project;

        } else {
          // this.router.navigate(['/404']);
          this.router.navigate(['/home']);
          return null;
        }
      })
    );
  }
}
