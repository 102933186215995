import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { CompaniesService } from '../companies/companies.service';
import { ProjectsService } from '../projects/projects.service';
import { ListsService } from '../lists/lists.service';
import { TasksService } from '../tasks/tasks.service';
import { UnsubscribeOnDestroy } from '../../classes/unsubscribe-on-destroy';
import { ItemsService } from '../items/items.service';
import { Company } from 'models/companies.model';
import { Project } from 'models/projects.model';
import { List } from 'models/lists.model';
import { Task } from 'models/tasks.model';
import { Item } from 'models/items.model';

interface Breadcrumb {
  url: string;
  name: string;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent extends UnsubscribeOnDestroy implements OnInit {
  elements: Array<Breadcrumb> = [{
    url: '/home',
    name: 'Home'
  }];

  company: Company;
  project: Project;
  lists: Array<List>;
  task: Task;
  item: Item;

  currentUrl = '';

  constructor(
    private companiesService: CompaniesService,
    private itemsService: ItemsService,
    private listsService: ListsService,
    private projectsService: ProjectsService,
    private router: Router,
    private tasksService: TasksService
  ) {
    super();

    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      distinctUntilChanged(),
      takeUntil(this.componentDestroyed$)
    )
      .subscribe((event: NavigationEnd) => {
        this.buildBreadcrumb(event.url);
      });
  }

  ngOnInit() {
    this.buildBreadcrumb(this.router.url);
  }

  // TODO: (DAS-2) https://medium.com/@bo.vandersteene/angular-5-breadcrumb-c225fd9df5cf
  buildBreadcrumb(url: string) {
    this.currentUrl = url;

    const parts = url.split('?')[0].split('/');
    parts.shift();

    const newElements: Array<Breadcrumb> = [];

    if (parts.length === 1 && parts[0] === 'home') {
      this.elements = [];
      return;

    } else if (parts.length === 1 && parts[0] === 'settings') {
      this.elements = [];
      return;

    } else if (parts.length >= 2) {
      switch (parts[0]) {

        // Companies
        case 'companies':
          this.populateCompany(newElements, parts[1]);
          break;

        // Projects
        case 'projects':
          this.populateProject(newElements, parts[1]);
          break;

        // Lists
        case 'lists':
          this.populateLists(newElements, parts[1]);
          break;

        // Tasks
        case 'tasks':
          this.populateTask(newElements, parts[1]);
          break;

        // Items
        case 'items':
          this.populateItem(newElements, parts[1]);
          break;
      }
    }
  }

  private populateCompany(newElements: Array<Breadcrumb>, companyId?: string) {
    if (this.company && this.company.id === companyId) {
      this.setCompanyCrumb([...newElements]);
      return;
    }

    this.companiesService.getCompany({companyId})
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(company => {
        this.company = company;
        this.setCompanyCrumb([...newElements]);
      });
  }

  private populateProject(newElements: Array<Breadcrumb>, projectId?: string) {
    if (this.project && this.project.id === projectId) {
      this.setProjectCrumb([...newElements]);
      return;
    }

    this.projectsService.getProject({projectId})
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(project => {
        this.project = project;
        this.setProjectCrumb([...newElements]);
      });
  }

  private populateLists(newElements: Array<Breadcrumb>, listId?: string, lists: Array<List> = []): Array<List> | void {
    const newLists = [...lists];
    this.listsService.getList({listId})
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(list => {
        newLists.unshift(list);
        if (list.parentListId) {
          this.populateLists([...newElements], list.parentListId, newLists);

        } else {
          this.lists = newLists.reverse();
          this.setListsCrumb([...newElements]);
        }
      });
  }

  private populateTask(newElements: Array<Breadcrumb>, taskId?: string) {
    if (this.task && this.task.id === taskId) {
      this.setTaskCrumb([...newElements]);
      return;
    }

    this.tasksService.getTask({taskId})
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(task => {
        this.task = task;
        this.setTaskCrumb([...newElements]);
      });
  }

  private populateItem(newElements: Array<Breadcrumb>, itemId?: string) {
    if (this.item && this.item.id === itemId) {
      this.setItemCrumb([...newElements]);
      return;
    }

    this.itemsService.getItem({itemId})
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(item => {
        this.item = item;
        this.setItemCrumb([...newElements]);
      });
  }

  private setCompanyCrumb(newElements: Array<Breadcrumb>): void {
    newElements.splice(0, 0, {
      url: '/companies/' + this.company.id,
      name: this.company.name
    });

    if (this.currentUrl.endsWith('/roadmap')) {
      newElements.push({
        url: '',
        name: 'Roadmap'
      });
    }

    this.elements = newElements.filter(function(n) { return n !== undefined; });
  }

  private setProjectCrumb(newElements: Array<Breadcrumb>): void {
    newElements.splice(1, 0, {
      url: '/projects/' + this.project.id,
      name: this.project.name
    });

    this.populateCompany(newElements, this.project.companyId);
  }

  private setListsCrumb(newElements: Array<Breadcrumb>): void {
    const projList = this.lists.pop();
    for (let i = 2 - newElements.length; i > 0; i--) {
      newElements[i] = undefined;
    }

    for (const list of this.lists) {
      newElements.splice(2, 0, {
        url: '/lists/' + list.id,
        name: list.name
      });
    }

    this.populateProject(newElements, projList.projectId);
  }

  private setTaskCrumb(newElements: Array<Breadcrumb>): void {
    for (let i = 3 - newElements.length; i > 0; i--) {
      newElements[i] = undefined;
    }

    newElements.splice(3, 0, {
      url: '/tasks/' + this.task.id,
      name: this.task.name
    });

    this.populateLists(newElements, this.task.parentListId);
  }

  private setItemCrumb(newElements: Array<Breadcrumb>): void {
    for (let i = 4 - newElements.length; i > 0; i--) {
      newElements[i] = undefined;
    }

    newElements.splice(4, 0, {
      url: '/items/' + this.item.id,
      name: this.item.name
    });

    if (this.item.taskObj && this.item.taskObj.id) {
      this.populateTask(newElements, this.item.taskObj.id);

    } else {
      this.populateProject(newElements, this.item.projectId);
    }
  }

}
