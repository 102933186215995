import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UploadService } from '../upload.service';
import { Upload } from '../../../classes/upload';
import * as _ from 'lodash';
import { ItemsService } from '../items.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Task, SimpleTask } from 'models/tasks.model';

@Component({
  selector: 'app-upload-form',
  templateUrl: './upload-form.component.html',
  styleUrls: ['./upload-form.component.scss']
})
export class UploadFormComponent implements OnInit {
  @Input() projectId: string;
  @Input() task?: Task;
  @ViewChild('fileInput') fileInput: ElementRef;

  uploads: Upload[];
  dropzoneActive = false;

  @Output() uploadsChange = new EventEmitter<Upload[]>();

  constructor(
    private itemsService: ItemsService,
    private toastr: ToastrService,
    private upSvc: UploadService
  ) {

  }

  ngOnInit() {
    this.uploads = [];
  }

  dropzoneState($event: boolean) {
    this.dropzoneActive = $event;
  }

  handleDrop(fileList: FileList) {
    const filesIndex = _.range(fileList.length);

    _.each(filesIndex, (idx) => {
      // TODO: check file size before starting upload
      if (fileList[idx].size / 1024 / 1024 > 2) {
        this.toastr.error('File is too large to upload.', fileList[idx].name);
        return;
      }

      const taskObj = this.task ? {
        id: this.task.id,
        name: this.task.name
      } as SimpleTask : null;

      const upload = new Upload({
        file: fileList[idx],
        projectId: this.projectId,
        taskObj: taskObj
      });
      this.upSvc.pushUpload(upload);
      this.uploads.push(upload);
      this.uploadsChange.emit(this.uploads);

      upload.snapshot.subscribe(
        () => {},
        () => {
          this.toastr.error('There was an issue uploading, please try again.', fileList[idx].name);
        },
        () => {
          _.pull(this.uploads, upload);
          this.uploadsChange.emit(this.uploads);

          this.itemsService.create({
            projectId: upload.projectId,
            taskObj: upload.taskObj || null,
            name: upload.file.name,
            description: '',
            type: 'file',
            fileMeta: {
              extension: upload.file.name.slice((Math.max(0, upload.file.name.lastIndexOf('.')) || Infinity) + 1),
              mimetype: upload.file.type,
              name: upload.name,
              bucket: environment.firebase.storageBucket,
              bucketPath: upload.bucketPath
            }
          });
        }
      );
    });

    this.fileInput.nativeElement.value = null;
  }

}
