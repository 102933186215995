import { Component, OnInit, Input } from '@angular/core';
import { UnsubscribeOnDestroy } from '../../classes/unsubscribe-on-destroy';
import { ItemComment } from 'models/item-comments.model';
import { ItemCommentsService } from './item-comments.service';
import { PopupService } from '../popups/popup.service';
import { takeUntil } from 'rxjs/operators';
import { Item } from 'models/items.model';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-item-comments',
  templateUrl: './item-comments.component.html',
  styleUrls: ['./item-comments.component.scss']
})
export class ItemCommentsComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() item: Item;

  itemComments: ItemComment[] = [];
  comment = '';

  currentUserId: string;

  constructor(
    private authService: AuthService,
    private itemCommentsService: ItemCommentsService,
    private popupService: PopupService,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.currentUserId = this.authService.getUserId();

    this.itemCommentsService.getItemComments({ itemId: this.item.id, projectId: this.item.projectId })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(itemComments => {
        this.itemComments = itemComments.sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return a.createdAt.seconds > b.createdAt.seconds ? -1 : 1;

          } else if (a.createdAt && !b.createdAt) {
            return 1;

          } else if (b.createdAt && !a.createdAt) {
            return -1;

          } else {
            return -1;
          }
        });
      });
  }

  createItemComment() {
    if (this.comment.trim().length) {
      this.itemCommentsService.create({
        projectId: this.item.projectId,
        taskId: this.item.taskId || null,
        itemId: this.item.id,
        comment: this.comment.trim()
      })
        .then(() => {
          this.comment = '';
        })
        .catch(err => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error(err);
        });
    }
  }

  isCommentOnlyEmoji(itemComment: ItemComment) {
    // https://thekevinscott.com/emojis-in-javascript/
    // https://stackoverflow.com/questions/30757193/find-out-if-character-in-string-is-emoji
    /* tslint:disable-next-line */
    const emojiRanges = '(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])|\u200D|\uFE0F| ';

    return !itemComment.comment.replace(new RegExp(emojiRanges, 'g'), '').length;
  }

  deleteItemComment(itemComment: ItemComment) {
    this.popupService.confirm({ message: `Are you sure you want to delete this comment?`, title: 'Delete comment?' })
      .then(() => {
        this.itemCommentsService.delete(itemComment)
          .catch(err => {
            this.toastr.error('Oops, something went wrong. Please try again.');
            console.error(err);
          });
      })
      .catch(() => { });
  }

}
