import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { ItemsService } from '../items.service';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { ToastrService } from 'ngx-toastr';
import { Item } from 'models/items.model';
import { ItemComment } from 'models/item-comments.model';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent extends UnsubscribeOnDestroy implements OnInit {
  item: Item;
  itemUrl: string;

  canPreview = false;

  itemComments: ItemComment[] = [];
  comment = '';

  constructor(
    private itemsService: ItemsService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.route.data
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((data: { item: Item }) => {
        this.item = data.item;
        this.setCanPreview();
        this.initItem();
      });
  }

  private initItem() {
    this.itemsService.getItem({ itemId: this.item.id })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(item => {
        this.item = item;
        this.setCanPreview();
        this.initItemUrl();
      });
  }

  private initItemUrl() {
    this.itemsService.getItemUrl(this.item, true)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(url => {
        this.itemUrl = url;
      });
  }

  private setCanPreview() {
    this.canPreview = this.item.type === 'file' && (
      !!this.item.fileMeta.previewImageURL ||
      this.item.fileMeta.mimetype.indexOf('image/') === 0 ||
      !!this.item.fileMeta.fileContentPreview ||
      this.item.fileMeta.mimetype === 'application/pdf' ||
      this.item.fileMeta.mimetype.indexOf('audio/') === 0 ||
      this.item.fileMeta.mimetype.indexOf('video/') === 0 ||
      !!this.item.fileMeta.bucketPathPreview
    );
  }

  downloadItem() {
    const processing = this.toastr.info(`Getting secure link...`, '', {
      positionClass: 'toast-bottom-center',
      timeOut: 0
    });

    this.itemsService.getItemUrl(this.item)
      .pipe(take(1))
      .subscribe(url => {
        // TODO: actually generate a download
        window.open(url, '_blank');
      }, err => {
        this.toastr.error('Oops, something went wrong.');
        console.error(err);
      }, () => {
        this.toastr.clear(processing.toastId);
      });
  }
}
