import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreService } from '../firestore.service';
import { TaskComment } from 'models/task-comments.model';

@Injectable({
  providedIn: 'root'
})
export class TaskCommentsService {

  constructor(
    private db: FirestoreService
  ) { }

  getTaskComments({ taskId, projectId }: { taskId: string, projectId: string }): Observable<TaskComment[]> {
    return this.db.collectionWithIds$<TaskComment>('taskComments', ref => {
      if (!taskId) {
        return ref.where(`projectId`, '==', projectId);
      } else {
        return ref.where(`taskId`, '==', taskId).where(`projectId`, '==', projectId);
      }
    });
  }

  getTaskComment({ taskCommentId }: { taskCommentId: string }): Observable<TaskComment> {
    return this.db.doc$<TaskComment>(`taskComments/${taskCommentId}`)
      .pipe(
        map(taskComment => {
          return {
            id: taskCommentId,
            ...taskComment
          } as TaskComment;
        })
      );
  }

  create(taskComment: TaskComment): Promise<firebase.firestore.DocumentReference> {
    return this.db.add<TaskComment>('taskComments', taskComment);
  }

  update(taskComment: TaskComment): Promise<void> {
    // TODO: (DAS-4) high chance of other data being manipulated here
    return this.db.update<TaskComment>(`taskComments/${taskComment.id}`, taskComment);
  }

  delete(taskComment: TaskComment): Promise<void> {
    return this.db.delete(`taskComments/${taskComment.id}`);
  }
}
