import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Observable, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { NgbTypeahead, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { ProjectsService } from '../projects.service';
import { UsersService } from '../../users/users.service';
import { UnsubscribeOnDestroy } from '../../../classes/unsubscribe-on-destroy';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { AuthService } from '../../core/auth.service';
import { SimpleUser } from 'models/users.model';

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() projectId: string;
  @Input() placeholder = '';
  @Input() placement: PlacementArray = 'bottom-left';
  @Input() initialUser: SimpleUser;
  @Input() noTermText = '';

  @Output() selectUser = new EventEmitter<SimpleUser | undefined>();

  users: SimpleUser[];
  currentUser: SimpleUser;

  // https://ng-bootstrap.github.io/#/components/typeahead/examples#basic
  model: any;
  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(
    private authService: AuthService,
    private projectsService: ProjectsService,
    private usersService: UsersService
  ) {
    super();
  }

  ngOnInit() {
    this.projectsService.getUsers({ projectId: this.projectId })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(users => {
        this.users = users.map(user => {
          return {
            id: user.id,
            email: user.email,
            name: user.name,
            photoURL: user.photoURL
          };
        }).sort((a, b) => {
          return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1;
        });
      });

    this.usersService.getUser({ userId: this.authService.getUserId() })
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(user => {
        this.currentUser = {
          id: user.id,
          email: user.email,
          name: user.name,
          photoURL: user.photoURL
        };
      });
  }

  // https://ng-bootstrap.github.io/#/components/typeahead/examples#basic
  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$)
      .pipe(
        map(term => {
          if (term.trim() === '') {
            this.selectUser.emit(undefined);

            return [
              {
                ...this.currentUser,
                tempName: this.noTermText || 'Assign to me'
              },
              ...this.users.filter(user => {
                return user.id !== this.currentUser.id;
              })
            ].slice(0, 10);
          } else {
            return this.users.filter(user => {
              return user.name.toLowerCase().indexOf(term.toLowerCase()) > -1;
            }).slice(0, 10);
          }
        })
      );
  }

  formatter(user: SimpleUser): string {
    return user.name;
  }

  selected(event: NgbTypeaheadSelectItemEvent) {
    const user: SimpleUser = event.item;

    if (user['tempName']) {
      delete user['tempName'];
    }

    this.selectUser.emit(user);
  }

}
