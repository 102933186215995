import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/auth.service';
import { User } from 'models/users.model';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CompaniesService } from '../companies/companies.service';
import { ProjectsService } from '../projects/projects.service';
import { UnsubscribeOnDestroy } from '../../classes/unsubscribe-on-destroy';
import { Company, CompanyAndProjects } from 'models/companies.model';
import { Project } from 'models/projects.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends UnsubscribeOnDestroy implements OnInit {
  user: User;
  isLoadingCompanies = true;
  companiesAndProjects: CompanyAndProjects[];

  toggled = false;

  constructor(
    private authService: AuthService,
    private companiesService: CompaniesService,
    private projectsService: ProjectsService,
  ) {
    super();
    this.authService.getUser()
      .subscribe(user => {
        this.user = user;
      });
  }

  ngOnInit() {
    combineLatest(
      this.companiesService.getCompanies(),
      this.projectsService.getProjects({ userId: this.authService.getUserId() })
    )
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(([companies, projects]) => {
        this.setCompanies(companies, projects);
        this.isLoadingCompanies = false;

      }, err => {
        console.error(err);
      });
  }

  private setCompanies(companies: Company[], projects: Project[]): void {
    this.companiesAndProjects = [];
    const sortedCompanies: Company[] = companies.sort((a, b) => {
      return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1;
    });

    for (const company of sortedCompanies) {
      this.companiesAndProjects.push({
        company,
        projects: projects.filter(project => project.companyId === company.id).sort((a, b) => {
          return (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 1;
        })
      });
    }
  }

  toggle() {
    this.toggled = !this.toggled;
  }

}
