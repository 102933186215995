import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserInvitesService } from '../../users/user-invites.service';
import { Company } from 'models/companies.model';

@Component({
  selector: 'app-invite-user-modal',
  templateUrl: './invite-user-modal.component.html',
  styleUrls: ['./invite-user-modal.component.scss']
})
export class InviteUserModalComponent implements OnInit {
  @Input() company: Company;

  emailAddress = '';

  constructor(
    private activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private userInvitesService: UserInvitesService
  ) { }

  ngOnInit() {
  }

  invite() {
    this.userInvitesService.inviteUserToCompany({
      companyId: this.company.id,
      companyName: this.company.name,
      email: this.emailAddress
    })
      .then(() => {
        this.closeModal();
      })
      .catch(err => {
        this.toastr.error('Oops, something went wrong. Please try again.');
        console.error(err);
      });
  }

  closeModal() {
    this.activeModal.close();
  }

}
