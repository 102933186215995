import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../core/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-verification-modal',
  templateUrl: './email-verification-modal.component.html',
  styleUrls: ['./email-verification-modal.component.scss']
})
export class EmailVerificationModalComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private authService: AuthService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
  }

  resend() {
    this.authService.sendEmailVerification()
      .then(() => {
        this.toastr.success('Your email verification has been re-sent.');
      })
      .catch();
  }

}
