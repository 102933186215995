import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestoreCollection } from '@angular/fire/firestore';
import { FirestoreService } from '../firestore.service';
import { User } from 'models/users.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  users: User[];

  constructor(
    private db: FirestoreService
  ) {
  }

  getUser({ userId }: { userId: string }): Observable<User> {
    return this.db.doc$<User>(`users/${userId}`)
      .pipe(
        map(user => {
          return { id: userId, ...user } as User;
        })
      );
  }

  createUser(user: User): Promise<void> {
    return this.db.set<User>(`users/${user.id}`, user);
  }

  updateUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      const userDoc = this.db.doc<User>(`users/${user.id}`);

      this.db.update(userDoc, user)
        .then(() => {
          resolve(user);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
