import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss']
})
export class SignInFormComponent implements OnInit {
  signInForm: FormGroup;
  email: FormControl;
  password: FormControl;
  remember: FormControl;
  submitted: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.submitted = false;
  }

  createFormControls() {
    this.email = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
    this.password = new FormControl('', [
      Validators.required
    ]);
    this.remember = new FormControl(true);
  }

  createForm() {
    this.signInForm = new FormGroup({
      email: this.email,
      password: this.password,
      remember: this.remember,
    });
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  resetValues() {
    [this.email, this.password].forEach((control: FormControl) => control.setValue(''));
    this.submitted = false;
  }

  signIn() {
    if (this.signInForm.status === 'INVALID') {
      this.submitted = true;

      return;
    }

    this.authService.signIn(this.email.value, this.password.value, this.remember.value)
      .then(() => {
        this.resetValues();
        this.router.navigate(['/home']);
      })
      .catch(err => {
        this.toastr.error('Oops, something went wrong. Please try again.');
        console.error('Something went wrong:', err);
        this.resetValues();
      });
  }

}
