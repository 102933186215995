import { Component, OnInit, Input } from '@angular/core';
import { ListsService } from '../lists.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { List } from 'models/lists.model';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateListComponent implements OnInit {
  submitted: boolean;
  listForm: FormGroup;
  name: FormControl;
  description: FormControl;

  @Input() projectId: string;
  @Input() parentListId: string;

  @Input() list: List = {
    projectId: '',
    parentListId: '',
    name: '',
    description: '',
    type: 'list'
  };

  constructor(
    private activeModal: NgbActiveModal,
    private listsService: ListsService,
    private toastr: ToastrService
  ) {
    this.submitted = false;
  }

  createFormControls() {
    this.name = new FormControl(this.list.name || '', [
      Validators.required,
    ]);
    this.description = new FormControl(this.list.description || '');
  }

  createForm() {
    this.listForm = new FormGroup({
      name: this.name,
      description: this.description,
    });
  }

  ngOnInit() {
    this.createFormControls();
    this.createForm();
  }

  save() {
    if (this.listForm.status === 'INVALID') {
      this.submitted = true;

      return;
    }

    // Create
    if (!this.list.id) {
      this.listsService.create({
        ...this.list,
        name: this.name.value,
        description: this.description.value,
        projectId: this.projectId,
        parentListId: this.parentListId
      })
        .then(() => {
          this.closeModal();
        })
        .catch((err) => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error(err);
        });

    // Update
    } else {
      // TODO: (DAS-4) should ONLY allow updating specific fields
      this.listsService.update({ ...this.list, name: this.name.value, description: this.description.value })
        .then(() => {
          this.closeModal();
        })
        .catch((err) => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error(err);
        });
    }
  }

  closeModal() {
    this.activeModal.close();
  }
}
