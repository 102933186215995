import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TasksService } from './tasks.service';
import { Task } from 'models/tasks.model';

@Injectable({
  providedIn: 'root'
})
export class TasksResolverService implements Resolve<Task> {

  constructor(
    private tasksService: TasksService,
    private router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Task> {
    let taskId = route.paramMap.get('taskId');
    if (!taskId) {
      taskId = route.queryParamMap.get('taskId');
    }

    return this.tasksService.getTask({taskId}).pipe(
      take(1),
      map(task => {
        if (task) {
          return task;

        } else {
          // this.router.navigate(['/404']);
          this.router.navigate(['/home']);
          return null;
        }
      })
    );
  }
}
