import { Component, OnInit, Input } from '@angular/core';

import { CompaniesService } from '../companies.service';
import { ToastrService } from 'ngx-toastr';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Company } from 'models/companies.model';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateCompanyComponent implements OnInit {
  submitted: boolean;
  companyForm: FormGroup;
  companyName: FormControl;
  @Input() company: Company = {
    name: '',
    users: {}
  };

  constructor(
    private activeModal: NgbActiveModal,
    private companiesService: CompaniesService,
    private toastr: ToastrService
  ) {
  }

  createFormControls() {
    this.companyName = new FormControl(this.company.name || '', [
      Validators.required
    ]);
  }

  createForm() {
    this.companyForm = new FormGroup({
      companyName: this.companyName,
    });
  }

  ngOnInit() {
    this.submitted = false;
    this.createFormControls();
    this.createForm();
  }

  save() {
    if (this.companyForm.status === 'INVALID') {
      this.submitted = true;

      return;
    }

    // Create
    if (!this.company.id) {
      this.companiesService.create({ ...this.company, name: this.companyName.value })
        .then(() => {
          this.closeModal();
        })
        .catch((err) => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error(err);
        });

    // Update
    } else {
      // TODO: (DAS-4) should ONLY allow updating specific fields
      this.companiesService.update({
        ...this.company,
        name: this.companyName.value,
      })
        .then(() => {
          this.closeModal();
        })
        .catch((err) => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error(err);
        });
    }
  }

  closeModal() {
    this.activeModal.close();
  }

}
