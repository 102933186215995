import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore.service';
import { AuditLog } from 'models/audit-logs.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuditLogsService {

  constructor(
    private db: FirestoreService
  ) { }

  getAuditLogs({
    projectId,
    docId,
    docParentId,
    docType
  }: { projectId: string, docId?: string, docParentId?: string, docType?: string }): Observable<AuditLog[]> {
    return this.db.collectionWithIds$<AuditLog>('auditLogs', ref => {
      let query = ref.where('projectId', '==', projectId);

      if (docId) {
        query = query.where(`docId`, '==', docId);
      }

      if (docParentId) {
        query = query.where(`docParentId`, '==', docParentId);
      }

      if (docType) {
        query = query.where(`docType`, '==', docType);
      }

      return query;
    })
    .pipe(
      map((auditLogs: AuditLog[]) => {
        return auditLogs.filter(auditLog => !auditLog.docDiff || Object.keys(auditLog.docDiff).length > 2);
      })
    );
  }
}
