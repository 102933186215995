import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertPopupComponent } from './alert-popup/alert-popup.component';
import { ConfirmPopupComponent } from './confirm-popup/confirm-popup.component';
import { PromptPopupComponent } from './prompt-popup/prompt-popup.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    private modalService: NgbModal
  ) { }

  alert({message, title}: {message: string, title: string}) {
    const modalRef = this.modalService.open(AlertPopupComponent);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;
  }

  confirm({message, title}: {message: string, title?: string}): Promise<any> {
    const modalRef = this.modalService.open(ConfirmPopupComponent);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;

    return new Promise((resolve, reject) => {
      modalRef.result
        .then(resolve)
        .catch(reject);
    });
  }

  prompt({message, title, field, fieldType = 'text'}: {
    message: string, title: string, field: string, fieldType?: 'text' | 'password'
  }): Promise<any> {
    const modalRef = this.modalService.open(PromptPopupComponent);
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.field = field;
    modalRef.componentInstance.fieldType = fieldType;

    return new Promise((resolve, reject) => {
      modalRef.result
        .then(resolve)
        .catch(reject);
    });
  }
}
