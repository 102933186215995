import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { User } from 'models/users.model';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit, OnChanges {
  @Input() user: User;
  @Input() size = '';
  @Input() tooltip = false;

  imgURL: string;
  defaultURL: string;

  constructor() {
  }

  ngOnInit() {
    this.defaultURL = `https://ui-avatars.com/api/?background=00003c&color=fff&size=128&name=${this.user.name}`;
    this.imgURL = this.user.photoURL ? this.user.photoURL : this.defaultURL;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['user']) {
      this.ngOnInit();
    }
  }

  imgError(event) {
    this.imgURL = this.defaultURL;
  }

}
