import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FirestoreService } from '../firestore.service';
import { ItemComment } from 'models/item-comments.model';

@Injectable({
  providedIn: 'root'
})
export class ItemCommentsService {

  constructor(
    private db: FirestoreService
  ) { }

  getItemComments({ itemId, projectId }: { itemId: string, projectId: string }): Observable<ItemComment[]> {
    return this.db.collectionWithIds$<ItemComment>('itemComments', ref => {
      if (!itemId) {
        return ref.where(`projectId`, '==', projectId);
      } else {
        return ref.where(`itemId`, '==', itemId).where(`projectId`, '==', projectId);
      }
    });
  }

  getItemComment({ itemCommentId }: { itemCommentId: string }): Observable<ItemComment> {
    return this.db.doc$<ItemComment>(`itemComments/${itemCommentId}`)
      .pipe(
        map(itemComment => {
          return {
            id: itemCommentId,
            ...itemComment
          } as ItemComment;
        })
      );
  }

  create(itemComment: ItemComment): Promise<firebase.firestore.DocumentReference> {
    return this.db.add<ItemComment>('itemComments', itemComment);
  }

  update(itemComment: ItemComment): Promise<void> {
    // TODO: (DAS-4) high chance of other data being manipulated here
    return this.db.update<ItemComment>(`itemComments/${itemComment.id}`, itemComment);
  }

  delete(itemComment: ItemComment): Promise<void> {
    return this.db.delete(`itemComments/${itemComment.id}`);
  }
}
