import { Injectable } from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { CompaniesService } from './companies.service';
import { Company } from 'models/companies.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesResolverService implements Resolve<Company> {

  constructor(
    private companiesService: CompaniesService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Company> {
    let companyId = route.paramMap.get('companyId');
    if (!companyId) {
      companyId = route.queryParamMap.get('companyId');
    }

    return this.companiesService.getCompany({companyId}).pipe(
      take(1),
      map(company => {
        if (company) {
          return company;

        } else {
          // this.router.navigate(['/404']);
          this.router.navigate(['/home']);
          return null;
        }
      })
    );
  }
}
