import { Component, OnInit, Input } from '@angular/core';
import { UnsubscribeOnDestroy } from '../../classes/unsubscribe-on-destroy';
import { Task } from 'models/tasks.model';
import { TaskComment } from 'models/task-comments.model';
import { PopupService } from '../popups/popup.service';
import { TaskCommentsService } from './task-comments.service';
import { AuthService } from '../core/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-task-comments',
  templateUrl: './task-comments.component.html',
  styleUrls: ['./task-comments.component.scss']
})
export class TaskCommentsComponent extends UnsubscribeOnDestroy implements OnInit {
  @Input() task: Task;

  taskComments: TaskComment[];
  comment = '';

  currentUserId: string;

  constructor(
    private authService: AuthService,
    private popupService: PopupService,
    private taskCommentsService: TaskCommentsService,
    private toastr: ToastrService
  ) {
    super();
  }

  ngOnInit() {
    this.currentUserId = this.authService.getUserId();

    this.taskCommentsService.getTaskComments({ taskId: this.task.id, projectId: this.task.projectId })
      .subscribe(taskComments => {
        this.taskComments = taskComments.sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            return a.createdAt.seconds > b.createdAt.seconds ? 1 : -1;

          } else if (a.createdAt && !b.createdAt) {
            return -1;

          } else if (b.createdAt && !a.createdAt) {
            return 1;

          } else {
            return 1;
          }
        });
      });
  }

  createTaskComment() {
    if (this.comment.trim().length) {
      this.taskCommentsService.create({
        projectId: this.task.projectId,
        taskId: this.task.id,
        comment: this.comment.trim()
      })
        .then(() => {
          this.comment = '';
        })
        .catch(err => {
          this.toastr.error('Oops, something went wrong. Please try again.');
          console.error(err);
        });
    }
  }

  deleteTaskComment(taskComment: TaskComment) {
    this.popupService.confirm({ message: `Are you sure you want to delete this comment?`, title: 'Delete comment?' })
      .then(() => {
        this.taskCommentsService.delete(taskComment)
          .catch(err => {
            this.toastr.error('Oops, something went wrong. Please try again.');
            console.error(err);
          });
      })
      .catch(() => { });
  }

}
