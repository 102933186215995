import { Component } from '@angular/core';
import { AuthService } from './core/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EmailVerificationModalComponent } from './users/email-verification-modal/email-verification-modal.component';
import { Router, NavigationEnd } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isAuthenticated = false;
  isEmailVerified = false;

  hasSidebar = false;
  private sidebarUrls = [
    '/items/'
  ];

  emailVerificationModalRef: NgbModalRef;

  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.authService.getAuthUser()
      .subscribe((user) => {
        this.isAuthenticated = !!user;
        this.isEmailVerified = !!user ? user.emailVerified : false;

        if (this.isAuthenticated && !this.isEmailVerified) {
          // TODO: (DAS-1) modal hides /auth-action feedback that's useful, also doesn't hide as
          // subscription here doesn't trigger on email verification... need better solution
          this.openEmailVerificationModal();

        } else if (this.emailVerificationModalRef) {
          this.emailVerificationModalRef.close();
        }
      });

    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        distinctUntilChanged(),
      )
      .subscribe((event: NavigationEnd) => {
        this.hasSidebar = false;
        for (const sidebarUrl of this.sidebarUrls) {
          if (event.url.indexOf(sidebarUrl) === 0) {
            this.hasSidebar = true;
            break;
          }
        }
      });
  }

  private openEmailVerificationModal() {
    this.emailVerificationModalRef = this.modalService.open(EmailVerificationModalComponent, {
      beforeDismiss: () => {
        return this.isEmailVerified;
      }
    });
  }
}
